import Web3 from "web3";
import KAP20TokenABI from "./abi/IKAP20.json";
import KAP20TokenKUSDTABI from "./abi/IKAP20KUSDT.json";
import MMVPairABI from "./abi/MorningMoonPair.json";
import MMVRouterABI from "./abi/MorningMoonRouter.json";
import SmartChefABI from "./abi/SmartChefInitializable.json";
import SmartChefV2ABI from "./abi/SmartChefV2.json";
import SmartFarmerABI from "./abi/SmartFarmerInitializable.json";
import TKFarmABI from "./abi/TKFarm.json";
import GamePlayDataABI from "./abi/GameplayData.json";
import GamePlayData2ABI from "./abi/GameplayData2.json";
import SmartCompoundABI from "./abi/SmartCompound.json";
import MorningMoonNFTABI from "./abi/MorningMoonNft.json";
import NPCCallHelperABI from "./abi/NpcCallHelper.json";
import NPCCallHelper3ABI from "./abi/NpcCallHelper3.json";
import KyleABI from "./abi/Kyle2.json";
import TheMayor2ABI from "./abi/TheMayor2.json";
import Mathilda2ABI from "./abi/Mathilda2.json";
import UncleJackABI from "./abi/UncleJack2.json";
import SvenABI from "./abi/Sven.json";
import FarmCentralABI from "./abi/FarmCentral.json";
import ConsumableMapABI from "./abi/ConsumableMap.json";
import TokenHolderHelper from "./abi/TokenHolderHelper.json";
import MulticallABI from "./abi/Multicall.json";
import MMVCropShopV1 from "./abi/MMVCropShopV1.json";
import MorningMoonWrappedToken from "./abi/MorningMoonWrappedToken.json"
import LumiStemUnwrapper from "./abi/LumiStemUnwrapper.json";
import MorningMoonDAO from "./abi/MorningMoonDAO.json";
import ResourceFarmABI from "./abi/ResourceFarm.json";
import KUBLumiGillShop from "./abi/KUBLumiGillShop.json";
import LumiGillStemHelper from "./abi/LumiGillStemHelper.json";
import { GetABIFromContractAlias } from "./Alias";
import { ABIDecoder } from "./ABIDecoder";

const MIN_NUMBER_OF_BLOCK_CONFIRMATION = 1;


export class ContractFuncExecuteDelegator {
    constructor(next) {
        this.next = next;
    }

    onTxSending(callID, payload) {
        console.debug(`[ContractFuncExecuteDelegator::onTxSending] callID: ${callID} payload: ${payload}`);
        if (this.next) this.next.onTxSending(callID, payload);
    }

    onTxSent(callID, payload) {
        console.debug(`[ContractFuncExecuteDelegator::onTxSent] callID: ${callID} payload: ${payload}`);
        if (this.next) this.next.onTxSent(callID, payload);
    }
    onTxHashed(callID, txHash) {
        console.debug(`[ContractFuncExecuteDelegator::onTxSent] callID: ${callID} txHash: ${txHash}`);
        if (this.next) this.next.onTxHashed(callID, txHash);
    }
    onTxGetReceipt(callID, receipt) {
        console.debug(`[ContractFuncExecuteDelegator::onTxGetReceipt] callID: ${callID} receipt: ${receipt}`);
        if (this.next) this.next.onTxGetReceipt(callID, receipt);
    }
    onTxConfirmed(callID, confNumber, receipt, latestBlockHash) {
        console.debug(`[ContractFuncExecuteDelegator::onTxConfirmed] callID: ${callID} ${{
            confNumber, receipt, latestBlockHash,
        }}`);
        if (this.next) this.next.onTxConfirmed(callID, confNumber, receipt, latestBlockHash);
    }
    onTxError(callID, error) {
        console.debug(`[ContractFuncExecuteDelegator::onTxError] callID: ${callID} error: ${error}`);
        if (this.next) this.next.onTxError(callID, error);
    }
    resultCallback(callID, result) {
        console.debug(`[ContractFuncExecuteDelegator::resultCallback] callID: ${callID} result: ${JSON.stringify(result)}`);
        if (this.next) this.next.resultCallback(callID, result);
    }
}

export class RPCCallBatchingDelegator {
    // reportBatchingCount reports 
    //
    // [1] numberOfCalls is number of calls have been batched into 1 multicall, 
    // for example 14 calls batched into 1 multicall (save 14-1 = 13 RPC call)
    // 
    // [2] numberOfCallSavedByL2Batching is number of redundant RPC calls (call that have the same signature - both contract address and its parameters)
    // is the same batch. they would have been batched (Level 2) before execute multicall
    // for example, in 1 batch, it might have 3 calls for get balance of the same token for the same wallet address
    // the 2 getBalance(sameTokenAddress, sameWalletAddress) calls would be removed from batch
    reportAnalytic(numberOfCalls, numberOfCallSavedByL2Batching) { }
}

export class ContractFuncExecutor {
    /**
     * 
     * @param {*} web3InstanceGetter () => { returns web3Instance } 
     * @param {ContractFuncExecuteDelegator} delegator
     * @param {RPCCallBatchingDelegator} batchingDelegator
     * @param {string} multicallAddress
     */
    constructor(web3InstanceGetter, delegator, batchingDelegator, multicallAddress) {
        this.web3Instance = web3InstanceGetter;
        this.funcMap = new Map();

        // when multicall enabled, function that has [batchabled = true], will be queued (into batchingQueue)
        // and batch into one multicall calling in the given interval
        this.MulticallEnabled = true;
        this.batchingQueue = [];
        this.batchingInterval = 100; // millisec 
        this.enableLevel2Batching = true;
        this.multicallAddress = multicallAddress

        if (delegator) {
            this.delegator = new ContractFuncExecuteDelegator(delegator);
        }

        if (batchingDelegator) {
            this.batchingDelegator = batchingDelegator;
        }
    }

    defaultGasLimit() {
        return 5_500_000;
    }

    async estimateGasPrice() {
        const price = await this.web3Instance().eth.getGasPrice();
        return price;
    }

    async defaultTxSendOptions(txObj = null, estimateGasOpt = null) {
        const gasPrice = await this.estimateGasPrice();
        let estimatedGasAmount = this.defaultGasLimit();

        try {
            if (txObj) {
                estimatedGasAmount = await txObj.estimateGas({ ...estimateGasOpt });
                estimatedGasAmount = 2 * estimatedGasAmount;
            }
        } catch (ex) {
            console.debug("[defaultTxSendOptions] got exception when estimateGas", ex);
        }

        return {
            gas: estimatedGasAmount,
            gasPrice: gasPrice,
        };
    }

    parseArgs(args) {
        if (typeof args === 'string') {
            return JSON.parse(args);
        }
        return args;
    }

    startBatchingInterval() {
        console.log("batching interval... started")
        setInterval(() => {
            this.batchingIntervalFunc();
        }, this.batchingInterval);
    }

    async batchingIntervalFunc() {
        if (this.batchingQueue.length === 0) {
            return
        }
        const batch = this.batchingQueue.splice(0, Math.min(this.batchingQueue.length, 30))
        const multicall = new (this.web3Instance()).eth.Contract(MulticallABI, this.multicallAddress);

        const calls = batch.map(e => e.method);
        const callIDs = batch.map(e => e.callID);
        const transformFuncs = batch.map(e => e.transformResultFunc);

        let numberOfCalls = calls.length;
        let numberOfCallsSavedByL2Batching = 0;

        if (this.enableLevel2Batching) {
            // batching...
            let batching = new Map();
            for (let i = 0; i < calls.length; i++) {
                const call = calls[i];
                const callData = call.encodeABI();
                const target = call._parent._address;
                const sig = `${target}${callData}`;
                if (!batching.has(sig)) {
                    batching.set(sig, {
                        call: calls[i],
                        callData: callData,
                        target: target,
                        transformFunc: transformFuncs[i],
                        callIDs: [],
                    });
                }
                let batch = batching.get(sig);
                batch.callIDs.push(callIDs[i]);
                batching.set(sig, batch);
            }

            const batches = Array.from(batching.values());
            const callRequests = batches.map(b => {
                return {
                    target: b.target,
                    callData: b.callData,
                };
            });

            let blockNumber, returnData = null;
            try {
                const { blockNumber: blockNumber_, returnData: returnData_ } = await multicall.methods.aggregate(callRequests).call();
                blockNumber = blockNumber_;
                returnData = returnData_;
            } catch (ex) {
                console.error(`$[batchingIntervalFunc] failed to call aggregate (l2BatchingEnaled) callIDs: ${JSON.stringify(callIDs)} error: ${ex} please see detail...`);
                console.error(ex);
                throw ex;
            }

            const callResult = returnData.map((hex, index) => {
                const types = batches[index].call._method.outputs.map(o =>
                    ((o.internalType !== o.type) && (o.internalType !== undefined)) ? o : o.type);
                let result = this.web3Instance().eth.abi.decodeParameters(types, hex);
                delete result.__length__;
                result = Object.values(result);
                if (result.length === 1) return result[0];
                return result;
            });
            numberOfCallsSavedByL2Batching = calls.length - callRequests.length;

            for (let i = 0; i < batches.length; i++) {
                const batch = batches[i];
                const result = batch.transformFunc(callResult[i]);
                for (const callID of batch.callIDs) {
                    this.delegator.resultCallback(callID, result);
                }
            }
        } else {
            const callRequests = calls.map(call => {
                const callData = call.encodeABI();
                return {
                    target: call._parent._address,
                    callData: callData,
                };
            });

            let blockNumber, returnData = null;
            try {
                const { blockNumber: blockNumber_, returnData: returnData_ } = await multicall.methods.aggregate(callRequests).call();
                blockNumber = blockNumber_;
                returnData = returnData_;
            } catch (ex) {
                console.error(`$[batchingIntervalFunc] failed to call aggregate callIDs: ${JSON.stringify(callIDs)} error: ${ex} please see detail...`);
                console.error(ex);
                throw ex;
            }

            const callResult = returnData.map((hex, index) => {
                const types = calls[index]._method.outputs.map(o =>
                    ((o.internalType !== o.type) && (o.internalType !== undefined)) ? o : o.type);
                let result = this.web3Instance().eth.abi.decodeParameters(types, hex);
                delete result.__length__;
                result = Object.values(result);
                if (result.length === 1) return result[0];
                return result;
            });

            for (let i = 0; i < callIDs.length; i++) {
                this.delegator.resultCallback(callIDs[i], transformFuncs[i](callResult[i]));
            }
        }

        if (this.batchingDelegator) {
            this.batchingDelegator.reportAnalytic(numberOfCalls, numberOfCallsSavedByL2Batching);
        }
    }

    async executeFunction(fnName, callID, args) {
        const fn = this.getFunctionMapping()[fnName];
        if (fn.isReadOnly) {
            if (fn.fn === undefined) {
                const method = fn.makeMethod(args);
                const result = await this.handleContractCall(`[${callID}-${fnName}]`, method);
                this.delegator.resultCallback(callID, fn.transformResult(result));
            } else {
                await fn.fn.bind(this)(callID, args);
            }
        } else {
            await fn.fn.bind(this)(callID, args);
        }
    }

    async enqueueBatchable(fnName, callID, args) {
        const fnMap = this.getFunctionMapping()[fnName];
        const method = fnMap.makeMethod(args);
        this.batchingQueue.push({
            callID: callID,
            method: method,
            transformResultFunc: (result) => {
                if (!fnMap.transformResult) {
                    console.error(">>>>> transformResult func of fn:", fnName, "is invalid");
                }
                return fnMap.transformResult(result);
            },
        });
    }

    isBatchableFunction(fnName) {
        const fn = this.getFunctionMapping()[fnName];
        return fn.batchable;
    }
    isReadOnlyFunction(fnName) {
        const fn = this.getFunctionMapping()[fnName];
        return fn.batchable;
    }
    isFunctionValid(fnName) {
        const fnMap = this.getFunctionMapping();
        if (!fnMap[fnName]) return false;
        return true;
    }

    getFunctionMapping() {
        /**
            function mapping value seperate into 3 types of object
            [1] Write function 
            {
                isReadOnly: false
                fn: create tx, sign and send request to RPC
                batchable: always false (can be omitted)
            }
            for example: approveKAP20, buyTheMayor
            
            [2] ReadOnly function - this kind of function, it blueprint contains 2 members:
            + makeMethod(args) takes the given arguments, load required ABI and create a contract binding method instance
            + transformResult(returnValue) takes the return value (from RPC call) and transform it into the response format 
            {
                isReadOnly: true
                makeMethod: (required) load ABI, make an instance of method call
                transformResult: (required) transform the returnValue from RPC to response, 
                                by expecting that if using multiCall the returned values is abi decoded in array form
                                however to make it support direct call, we have to expect that result might be in Object form
                                so almost, the case that return multiple value, transformValue might handle it as either array or object
                batchable: true|false
            }
            for example: getKAP20Balance, getSeedBalance, uncleJackGetRecipes

            [3] ReadOnly + Direct call - this kind of function, it might or might not make a contract call, it is just for some cases that
            + interacting with RPC to read balance, current block, query receipt
            + make a complicate read-only call to contract (which can't be batched for multicall) such as for calculating APR
            note that: this kind of function must have "fn" field
            {
                isReadOnly: true
                fn: (required) read-only function
                batchable: false
            }
            for example: getBlockNumber, queryTransactionLogs
        */

        const logTag = `[ContractExecutor::getFunctionMapping]`;
        const fnMap = {
            "getMyWalletAddress": {
                fn: this.getMyWalletAddress,
                isReadOnly: true,
                batchable: false,
            },
            "getMyKUBBalance": {
                fn: this.getMyKUBBalance,
                isReadOnly: true,
                batchable: false,
            },
            "getKAP20BalanceOf": {
                // fn: this.getKAP20BalanceOf,
                makeMethod: (args) => {
                    const { contractAddress, address } = this.parseArgs(args);
                    const contract = this.makeContract(KAP20TokenABI, contractAddress);
                    return contract.methods.balanceOf(address);
                },
                transformResult: (result) => {
                    return { balance: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "getKAP20Allowance": {
                // fn: this.getKAP20Allowance,
                makeMethod: (args) => {
                    const { tokenAddress, ownerAddress, spenderAddress } = this.parseArgs(args);
                    let remaining = 0;
                    const kusdtTestnet = "0x1f86f79f109060725b6f4146baee9b7aca41267d";
                    const kusdtMainnet = "0x7d984c24d2499d840eb3b7016077164e15e5faa6";
                    if (tokenAddress.toLowerCase() === kusdtTestnet ||
                        tokenAddress.toLowerCase() === kusdtMainnet) {
                        const contract = this.makeContract(KAP20TokenKUSDTABI, tokenAddress);
                        return contract.methods.allowances(ownerAddress, spenderAddress);
                    } else {
                        const contract = this.makeContract(KAP20TokenABI, tokenAddress);
                        return contract.methods.allowance(ownerAddress, spenderAddress);
                    }
                },
                transformResult: (result) => {
                    return { remaining: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "getCurrentBlockNumber": {
                fn: this.getCurrentBlockNumber,
                isReadOnly: true,
                batchable: false,
            },

            "approveLiquidityPool": {
                fn: this.approveLiquidityPool,
                isReadOnly: false,
                batchable: false,
            },
            "getLiquidityPoolAllowance": {
                // fn: this.getLiquidityPoolAllowance,
                makeMethod: (args) => {
                    const {
                        poolContractAddress,
                        ownerAddress,
                        spenderAddress,
                    } = this.parseArgs(args);
                    const contract = this.makeContract(MMVPairABI, poolContractAddress);
                    return contract.methods.allowance(ownerAddress, spenderAddress);
                },
                transformResult: (result) => {
                    return {
                        remaining: result,
                    };
                },
                isReadOnly: true,
                batchable: true,
            },
            "getBalanceOfLiquidityPool": {
                // fn: this.getBalanceOfLiquidityPool,
                makeMethod: (args) => {
                    const { poolContractAddress, address } = this.parseArgs(args);
                    const contract = this.makeContract(MMVPairABI, poolContractAddress);
                    return contract.methods.balanceOf(address);
                },
                transformResult: (result) => {
                    return {
                        balance: result,
                    };
                },
                isReadOnly: true,
                batchable: true,
            },
            "getLiquidityPoolTotalSupply": {
                // fn: this.getLiquidityPoolTotalSupply,
                makeMethod: (args) => {
                    const { poolContractAddress } = this.parseArgs(args);
                    const contract = this.makeContract(MMVPairABI, poolContractAddress);
                    return contract.methods.totalSupply();
                },
                transformResult: (result) => {
                    return {
                        totalSupply: result
                    };
                },
                isReadOnly: true,
                batchable: true,
            },
            "getLiquidityPoolReserves": {
                // fn: this.getLiquidityPoolReserves,
                makeMethod: (args) => {
                    const { poolContractAddress } = this.parseArgs(args);
                    const contract = this.makeContract(MMVPairABI, poolContractAddress);
                    return contract.methods.getReserves();
                },
                transformResult: (result) => {
                    let _, reserve0, reserve1;
                    if (Array.isArray(result)) {
                        [reserve0, reserve1, _] = result;
                    } else {
                        const { reserve0: r0, reserve1: r1 } = result;
                        reserve0 = r0;
                        reserve1 = r1;
                    }
                    return {
                        reserve0,
                        reserve1,
                    };
                },
                isReadOnly: true,
                batchable: true,
            },
            "getLiquidityPoolToken0Address": {
                // fn: this.getLiquidityPoolToken0Address,
                makeMethod: (args) => {
                    const { poolContractAddress } = this.parseArgs(args);
                    const contract = this.makeContract(MMVPairABI, poolContractAddress);
                    return contract.methods.token0();
                },
                transformResult: (result) => {
                    return { address: result.toLowerCase() };
                },
                isReadOnly: true,
                batchable: true,
            },
            "getLiquidityPoolToken1Address": {
                // fn: this.getLiquidityPoolToken1Address,
                makeMethod: (args) => {
                    const { poolContractAddress } = this.parseArgs(args);
                    const contract = this.makeContract(MMVPairABI, poolContractAddress);
                    return contract.methods.token1();
                },
                transformResult: (result) => {
                    return { address: result.toLowerCase() };
                },
                isReadOnly: true,
                batchable: true,
            },

            "approveSeedToken": {
                fn: this.approveSeedToken,
                isReadOnly: false,
                batchable: false,
            },
            "approveKAP20Token": {
                fn: this.approveKAP20Token,
                isReadOnly: false,
                batchable: false,
            },
            "getSeedTokenAllowance": {
                // fn: this.getSeedTokenAllowance,
                makeMethod: (args) => {
                    const { seedContractAddress, ownerAddress, spenderAddress } = this.parseArgs(args);
                    const contract = this.makeContract(KAP20TokenABI, seedContractAddress);
                    return contract.methods.allowance(ownerAddress, spenderAddress);
                },
                transformResult: (result) => {
                    return { remaining: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "getBalanceOfSeedToken": {
                // fn: this.getBalanceOfSeedToken,
                makeMethod: (args) => {
                    const { seedContractAddress, address } = this.parseArgs(args);
                    const contract = this.makeContract(KAP20TokenABI, seedContractAddress);
                    return contract.methods.balanceOf(address);
                },
                transformResult: (result) => {
                    return { balance: result };
                },
                isReadOnly: true,
                batchable: true,
            },

            // #region - StemFarm

            "getStemFarmUserInfo": {
                // fn: this.getStemFarmUserInfo,
                makeMethod: (args) => {
                    const { farmContractAddress, address } = this.parseArgs(args);
                    const contract = this.makeContract(SmartChefABI, farmContractAddress);
                    return contract.methods.userInfo(address);
                },
                transformResult: (result) => {
                    let amount, rewardDebt;
                    if (Array.isArray(result)) {
                        [amount, rewardDebt] = result;
                    } else {
                        const { amount: a, rewardDebt: r } = result;
                        amount = a;
                        rewardDebt = r;
                    }
                    return {
                        userInfo: {
                            amount,
                            rewardDebt,
                        },
                    };
                },
                isReadOnly: true,
                batchable: true,
            },
            "getStemTKFarmUserInfo": {
                // fn: this.getStemFarmUserInfo,
                makeMethod: (args) => {
                    const { farmContractAddress, address, tkFarmPoolID } = this.parseArgs(args);
                    const contract = this.makeContract(TKFarmABI, farmContractAddress);
                    return contract.methods.userInfo(tkFarmPoolID, address)
                },
                transformResult: (result) => {
                    let amount, rewardDebt;
                    if (Array.isArray(result)) {
                        [amount, rewardDebt] = result;
                    } else {
                        const { amount: a, rewardDebt: r } = result;
                        amount = a;
                        rewardDebt = r;
                    }
                    return {
                        userInfo: {
                            amount,
                            rewardDebt,
                        },
                    };
                },
                isReadOnly: true,
                batchable: true,
            },
            "getStemFarmRewardPerBlock": {
                // fn: this.getStemFarmRewardPerBlock,
                makeMethod: (args) => {
                    const { farmContractAddress } = this.parseArgs(args);
                    const contract = this.makeContract(SmartChefABI, farmContractAddress);
                    return contract.methods.getRewardPerBlock();
                },
                transformResult: (result) => {
                    return {
                        rewardPerBlock: result,
                    };
                },
                isReadOnly: true,
                batchable: true,
            },
            "getStemTKFarmRewardPerBlock": {
                fn: this.getStemTKFarmRewardPerBlock,
                isReadOnly: true,
                batchable: false,
            },
            "getStemFarmBaseRewardMultiplier": {
                fn: this.getStemFarmBaseRewardMultiplier,
                isReadOnly: true,
                batchable: false, // we do not set as batchabled since, getting multiplier needs 2 sequentially calls 
            },
            "getStemTKFarmBaseRewardMultiplier": {
                makeMethod: (args) => {
                    const { farmContractAddress, tkFarmPoolID } = this.parseArgs(args);
                    const contract = this.makeContract(TKFarmABI, farmContractAddress);
                    return contract.methods.poolInfo(tkFarmPoolID);
                },
                transformResult: (result) => {
                    // result as a poolInfo
                    const { allocPoint: multiplier } = result;
                    return {
                        multiplier,
                    };
                },
                isReadOnly: true,
                batchable: true,
            },
            "getStemFarmPendingReward": {
                // fn: this.getStemFarmPendingReward,
                makeMethod: (args) => {
                    const { farmContractAddress, address } = this.parseArgs(args);
                    const contract = this.makeContract(SmartChefABI, farmContractAddress);
                    return contract.methods.pendingReward(address);
                },
                transformResult: (result) => {
                    return { pendingReward: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "getStemTKFarmPendingReward": {
                // fn: this.getStemFarmPendingReward,
                makeMethod: (args) => {
                    const { farmContractAddress, address, tkFarmPoolID } = this.parseArgs(args);
                    const contract = this.makeContract(TKFarmABI, farmContractAddress);
                    return contract.methods.pendingReward(tkFarmPoolID, address);
                },
                transformResult: (result) => {
                    return { pendingReward: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "depositToStemFarm": {
                fn: this.depositToStemFarm,
                isReadOnly: false,
                batchable: false,
            },
            "depositToStemTKFarm": {
                fn: this.depositToStemTKFarm,
                isReadOnly: false,
                batchable: false,
            },
            "withdrawFromStemFarm": {
                fn: this.withdrawFromStemFarm,
                isReadOnly: false,
                batchable: false,
            },
            "withdrawFromStemTKFarm": {
                fn: this.withdrawFromStemTKFarm,
                isReadOnly: false,
                batchable: false,
            },
            "harvestFromStemFarm": {
                fn: this.harvestFromStemFarm,
                isReadOnly: false,
                batchable: false,
            },
            "harvestFromStemTKFarm": {
                fn: this.harvestFromStemTKFarm,
                isReadOnly: false,
                batchable: false,
            },

            // #endregion - StemFarm

            // #region - StemFarmV2

            "stemFarmV2GetUserInfo": {
                // fn: this.stemFarmV2GetUserInfo,
                makeMethod: (args) => {
                    const { farmContractAddress, address } = this.parseArgs(args);
                    const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
                    return contract.methods.userInfo(address);
                },
                transformResult: (result) => {
                    let amount, nftPower, nftPowerBeforeMultiply, rewardDebt, quotaUsed;
                    if (Array.isArray(result)) {
                        [amount, nftPower, nftPowerBeforeMultiply, rewardDebt, quotaUsed] = result;
                    } else {
                        const { amount: a, nftPower: n, rewardDebt: r, quotaUsed: q } = result;
                        amount = a;
                        nftPower = n;
                        rewardDebt = r;
                        quotaUsed = q;
                    }
                    return {
                        userInfo: {
                            amount,
                            nftPower,
                            rewardDebt,
                            quotaUsed,
                        },
                    };
                },
                isReadOnly: true,
                batchable: true,
            },

            "stemFarmV2GetTotalStakedValue": {
                // fn: this.stemFarmV2GetTotalStakedValue,
                makeMethod: (args) => {
                    const { farmContractAddress } = this.parseArgs(args);
                    const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
                    return contract.methods.totalStakedValue();
                },
                transformResult: (result) => {
                    return {
                        totalStakedValue: result,
                    };
                },
                isReadOnly: true,
                batchable: true,
            },

            "stemFarmV2GetUserStakedNFTLength": {
                // fn: this.stemFarmV2GetUserStakedNFTLength,
                makeMethod: (args) => {
                    const { farmContractAddress, address } = this.parseArgs(args);
                    const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
                    return contract.methods.userStakedNFTLength(address);
                },
                transformResult: (result) => {
                    return {
                        numberOfStakedNFTs: result,
                    };
                },
                isReadOnly: true,
                batchable: true,
            },

            "stemFarmV2GetUserStakedNFTs": {
                // fn: this.stemFarmV2GetUserStakedNFTs,
                makeMethod: (args) => {
                    let { farmContractAddress, address, page, limit } = this.parseArgs(args);
                    if (!page) page = 1;
                    if (!limit) limit = 100;
                    const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
                    return contract.methods.userStakedNFTs(address, page, limit);
                },
                transformResult: (result) => {
                    return {
                        stakedNFTs: result,
                    };
                },
                isReadOnly: true,
                batchable: true,
            },

            "stemFarmV2GetRewardPerBlock": {
                // fn: this.stemFarmV2GetRewardPerBlock,
                makeMethod: (args) => {
                    const { farmContractAddress } = this.parseArgs(args);
                    const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
                    return contract.methods.getRewardPerBlock();
                },
                transformResult: (result) => {
                    return {
                        rewardPerBlock: result,
                    };
                },
                isReadOnly: true,
                batchable: true,
            },
            "stemFarmV2GetBaseRewardMultiplier": {
                fn: this.stemFarmV2GetBaseRewardMultiplier,
                isReadOnly: true,
                batchable: false, // we do not set as batchabled since, getting multiplier needs 2 sequentially calls 
            },
            "stemFarmV2GetPendingReward": {
                // fn: this.stemFarmV2GetPendingReward,
                makeMethod: (args) => {
                    const { farmContractAddress, address } = this.parseArgs(args);
                    const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
                    return contract.methods.pendingReward(address);
                },
                transformResult: (result) => {
                    return { pendingReward: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "stemFarmV2GetFarmLevel": {
                // fn: this.stemFarmV2GetFarmLevel,
                makeMethod: (args) => {
                    const { farmContractAddress, address } = this.parseArgs(args);
                    const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
                    return contract.methods.farmLevel(address);
                },
                transformResult: (result) => {
                    return { farmLevel: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "stemFarmV2GetUpgradePrice": {
                // fn: this.stemFarmV2GetUpgradePrice,
                makeMethod: (args) => {
                    const { farmContractAddress, index } = this.parseArgs(args);
                    const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
                    return contract.methods.upgradePrice(index);
                },
                transformResult: (result) => {
                    return { price: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "stemFarmV2GetStakePowerMultiplier": {
                // fn: this.stemFarmV2GetStakePowerMultipliers,
                makeMethod: (args) => {
                    const { farmContractAddress, index } = this.parseArgs(args);
                    const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
                    return contract.methods.stakePowerMultiplier(index);
                },
                transformResult: (result) => {
                    return { multiplier: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "stemFarmV2Deposit": {
                fn: this.stemFarmV2Deposit,
                isReadOnly: false,
                batchable: false,
            },
            "stemFarmV2Withdraw": {
                fn: this.stemFarmV2Withdraw,
                isReadOnly: false,
                batchable: false,
            },
            "stemFarmV2Harvest": {
                fn: this.stemFarmV2Harvest,
                isReadOnly: false,
                batchable: false,
            },
            "stemFarmV2DepositNFTs": {
                fn: this.stemFarmV2DepositNFTs,
                isReadOnly: false,
                batchable: false,
            },
            "stemFarmV2WithdrawNFTs": {
                fn: this.stemFarmV2WithdrawNFTs,
                isReadOnly: false,
                batchable: false,
            },
            "stemFarmV2UpgradeFarm": {
                fn: this.stemFarmV2UpgradeFarm,
                isReadOnly: false,
                batchable: false,
            },

            // #endregion - StemFarmV2

            "getSeedFarmUserInfo": {
                // fn: this.getSeedFarmUserInfo,
                makeMethod: (args) => {
                    const { farmContractAddress, address } = this.parseArgs(args);
                    const contract = this.makeContract(SmartFarmerABI, farmContractAddress);
                    return contract.methods.userInfo(address);
                },
                transformResult: (result) => {
                    let userIndex, amount, rewardDebt, depositBlock;
                    if (Array.isArray(result)) {
                        [userIndex, amount, rewardDebt, depositBlock] = result;
                    } else {
                        const { userIndex: uID, amount: a, rewardDebt: r, depositBlock: d } = result;
                        userIndex = uID;
                        amount = a;
                        rewardDebt = r;
                        depositBlock = d;
                    }
                    return {
                        userInfo: {
                            userIndex,
                            amount,
                            rewardDebt,
                            depositBlock
                        },
                    };
                },
                isReadOnly: true,
                batchable: true,
            },
            "getSeedFarmRewardPerBlock": {
                // fn: this.getSeedFarmRewardPerBlock,
                makeMethod: (args) => {
                    const { farmContractAddress } = this.parseArgs(args);
                    const contract = this.makeContract(SmartFarmerABI, farmContractAddress);
                    return contract.methods.getRewardPerBlock();
                },
                transformResult: (result) => {
                    return { rewardPerBlock: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "getSeedFarmBaseRewardMultiplier": {
                fn: this.getSeedFarmBaseRewardMultiplier,
                isReadOnly: true,
                batchable: false,
            },
            "getSeedFarmStakedTokenSupply": {
                // fn: this.getSeedFarmStakedTokenSupply,
                makeMethod: (args) => {
                    const { farmContractAddress } = this.parseArgs(args);
                    const contract = this.makeContract(SmartFarmerABI, farmContractAddress);
                    return contract.methods.stakedTokenSupply();
                },
                transformResult: (result) => {
                    return { stakedTokenSupply: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "getSeedFarmPendingReward": {
                // fn: this.getSeedFarmPendingReward,
                makeMethod: (args) => {
                    const { farmContractAddress, address } = this.parseArgs(args);
                    const contract = this.makeContract(SmartFarmerABI, farmContractAddress);
                    return contract.methods.pendingReward(address);
                },
                transformResult: (result) => {
                    return { pendingReward: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "getSeedFarmNumberOfBlockToWither": {
                // fn: this.getSeedFarmNumberOfBlockToWither,
                makeMethod: (args) => {
                    const { farmContractAddress } = this.parseArgs(args);
                    const contract = this.makeContract(SmartFarmerABI, farmContractAddress);
                    return contract.methods.numBlockToWither();
                },
                transformResult: (result) => {
                    return { numberOfBlockToWither: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "depositToSeedFarm": {
                fn: this.depositToSeedFarm,
                isReadOnly: false,
                batchable: false,
            },
            "harvestFromSeedFarm": {
                fn: this.harvestFromSeedFarm,
                isReadOnly: false,
                batchable: false,
            },

            "resourceFarmGetSeedFarmAddress": {
                makeMethod: (args) => {
                    const { farmContractAddress } = this.parseArgs(args);
                    const contract = this.makeContract(ResourceFarmABI, farmContractAddress);
                    return contract.methods.seedFarm();
                },
                transformResult: (result) => {
                    return { seedFarmAddress: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "resourceFarmHasGrantLicense": {
                makeMethod: (args) => {
                    const { farmContractAddress, address } = this.parseArgs(args);
                    const contract = this.makeContract(ResourceFarmABI, farmContractAddress);
                    return contract.methods.hasGranted(address);
                },
                transformResult: (result) => {
                    return { granted: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "resourceFarmGrantLicense": {
                fn: this.resourceFarmGrantLicense,
                isReadOnly: false,
                batchable: false,
            },
            "resourceFarmRevokeLicense": {
                fn: this.resourceFarmRevokeLicense,
                isReadOnly: false,
                batchable: false,
            },
            "resourceFarmDeposit": {
                fn: this.resourceFarmDeposit,
                isReadOnly: false,
                batchable: false,
            },

            "getSwappingAmountOut": {
                // fn: this.getSwappingAmountOut,
                makeMethod: (args) => {
                    const {
                        routerContractAddress,
                        amountIn,
                        reserveInTokenAddress,
                        reserveOutTokenAddress,
                    } = this.parseArgs(args);
                    const contract = this.makeContract(MMVRouterABI, routerContractAddress);
                    return contract.methods.getAmountsOut(
                        amountIn,
                        [reserveInTokenAddress, reserveOutTokenAddress],
                    );
                },
                transformResult: (result) => {
                    let _, amountOut;
                    if (Array.isArray(result)) {
                        [_, amountOut] = result;
                    } else {
                        const { amountOut: a } = result;
                        amountOut = a;
                    }
                    return { amountOut };
                },
                isReadOnly: true,
                batchable: true,
            },
            "getSwappingAmountOutWithPath": {
                // fn: this.getSwappingAmountOutWithPath,
                makeMethod: (args) => {
                    const {
                        routerContractAddress,
                        amountIn,
                        swappingPath,
                    } = this.parseArgs(args);
                    const contract = this.makeContract(MMVRouterABI, routerContractAddress);
                    return contract.methods.getAmountsOut(
                        amountIn,
                        swappingPath,
                    );
                },
                transformResult: (result) => {
                    let _, amountOut;
                    if (Array.isArray(result)) {
                        amountOut = result[result.length - 1];
                    } else {
                        const { amountOut: a } = result;
                        amountOut = a;
                    }
                    return { amountOut };
                },
                isReadOnly: true,
                batchable: true,
            },
            "getSwappingAmountIn": {
                // fn: this.getSwappingAmountIn,
                makeMethod: (args) => {
                    const {
                        routerContractAddress,
                        amountOut,
                        reserveInTokenAddress,
                        reserveOutTokenAddress,
                    } = this.parseArgs(args);
                    const contract = this.makeContract(MMVRouterABI, routerContractAddress);
                    return contract.methods.getAmountsIn(
                        amountOut,
                        [reserveInTokenAddress, reserveOutTokenAddress],
                    );
                },
                transformResult: (result) => {
                    let _, amountIn;
                    if (Array.isArray(result)) {
                        [amountIn, _] = result;
                    } else {
                        const { amountIn: a } = result;
                        amountIn = a;
                    }
                    return { amountIn };
                },
                isReadOnly: true,
                batchable: true,
            },
            "swap": {
                fn: this.swap,
                isReadOnly: false,
                batchable: false,
            },
            "swapTokensForExactTokens": {
                fn: this.swapTokensForExactTokens,
                isReadOnly: false,
                batchable: false,
            },
            "swapKUB": {
                fn: this.swapKUB,
                isReadOnly: false,
                batchable: false,
            },

            "smartCompoundZap": {
                fn: this.smartCompoundZap,
                isReadOnly: false,
                batchable: false,
            },
            "smartCompoundZapKUB": {
                fn: this.smartCompoundZapKUB,
                isReadOnly: false,
                batchable: false,
            },
            "removeLPOneSide": {
                fn: this.removeLPOneSide,
                isReadOnly: false,
                batchable: false,
            },

            "getTxReceipt": {
                fn: this.getTxReceipt,
                isReadOnly: true,
                batchable: false,
            },
            "queryTransactionLogs": {
                fn: this.queryTransactionLogs2,
                isReadOnly: true,
                batchable: false,
            },

            "getGamePlayData": {
                // fn: this.getGamePlayData,
                makeMethod: (args) => {
                    const {
                        contractAddress,
                        address,
                    } = this.parseArgs(args);
                    const contract = this.makeContract(GamePlayDataABI, contractAddress);
                    return contract.methods.gameplayData(address);
                },
                transformResult: (result) => {
                    return { data: result };
                },
                isReadOnly: true,
                batchable: true,
            },

            "gameplayData2GetResourceAmountsByTypes": {
                makeMethod: (args) => {
                    const {
                        contractAddress,
                        address,
                        resourceTypes,
                    } = this.parseArgs(args);
                    const contract = this.makeContract(GamePlayData2ABI, contractAddress);
                    return contract.methods.getResourceAmountsByTypes(address, resourceTypes);
                },
                transformResult: (result) => {
                    return { values: result };
                },
                isReadOnly: true,
                batchable: true,
            },

            "nftGetTokenOfOwnerAll": {
                // fn: this.nftGetTokenOfOwnerAll,
                makeMethod: (args) => {
                    const {
                        contractAddress,
                        ownerAddress,
                    } = this.parseArgs(args);
                    const contract = this.makeContract(MorningMoonNFTABI, contractAddress);
                    return contract.methods.tokenOfOwnerAll(ownerAddress);
                },
                transformResult: (result) => {
                    return { tokens: result };
                },
                isReadOnly: true,
                batchable: false,
            },
            "nftGetTokenOfOwnerByPage": {
                // fn: this.nftGetTokenOfOwnerByPage,
                makeMethod: (args) => {
                    const {
                        contractAddress,
                        ownerAddress,
                        page,
                        limit,
                    } = this.parseArgs(args);
                    const contract = this.makeContract(MorningMoonNFTABI, contractAddress);
                    return contract.methods.tokenOfOwnerByPage(ownerAddress, page, limit);
                },
                transformResult: (result) => {
                    return { tokens: result };
                },
                isReadOnly: true,
                batchable: false,
            },

            "freezeNFT": {
                fn: this.freezeNFT,
                isReadOnly: false,
                batchable: false,
            },
            "isNFTFreezed": {
                // fn: this.isNFTFreezed,
                makeMethod: (args) => {
                    const {
                        nftContractAddress,
                        ownerAddress,
                    } = this.parseArgs(args);
                    const contract = this.makeContract(MorningMoonNFTABI, nftContractAddress);
                    return contract.methods.freezed(ownerAddress);
                },
                transformResult: (result) => {
                    return { freezed: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "mmNFTIsApprovedForAll": {
                // fn: this.mmNFTIsApprovedForAll,
                makeMethod: (args) => {
                    const {
                        nftContractAddress,
                        ownerAddress,
                        operatorAddress,
                    } = this.parseArgs(args);
                    const contract = this.makeContract(MorningMoonNFTABI, nftContractAddress);
                    return contract.methods.isApprovedForAll(ownerAddress, operatorAddress);
                },
                transformResult: (result) => {
                    return { approved: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "mmNFTSetApprovalForAll": {
                fn: this.mmNFTSetApprovalForAll,
                isReadOnly: false,
                batchable: false,
            },

            "signMessage": {
                fn: this.signMessage,
                isReadOnly: false,
                batchable: false,
            },

            "craftUncleJack": {
                fn: this.craftUncleJack2,
                isReadOnly: false,
                batchable: false,
            },
            "sellKyle": {
                fn: this.sellKyle,
                isReadOnly: false,
                batchable: false,
            },
            "craftMathilda": {
                fn: this.craftMathilda,
                isReadOnly: false,
                batchable: false,
            },
            "buyTheMayor": {
                fn: this.buyTheMayor,
                isReadOnly: false,
                batchable: false,
            },
            "buySven": {
                fn: this.buySven,
                isReadOnly: false,
                batchable: false,
            },

            "uncleJackFavorPerUser": {
                // fn: this.uncleJackFavorPerUser,
                makeMethod: (args) => {
                    const { contractAddress, address } = this.parseArgs(args);
                    const contract = this.makeContract(UncleJackABI, contractAddress);
                    return contract.methods.favorPerUser(address);
                },
                transformResult: (result) => {
                    return { value: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "kyleFavorPerUser": {
                // fn: this.kyleFavorPerUser,
                makeMethod: (args) => {
                    const { contractAddress, address } = this.parseArgs(args);
                    const contract = this.makeContract(KyleABI, contractAddress);
                    return contract.methods.favorPerUser(address);
                },
                transformResult: (result) => {
                    return { value: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "mathildaFavorPerUser": {
                // fn: this.mathildaFavorPerUser,
                makeMethod: (args) => {
                    const { contractAddress, address } = this.parseArgs(args);
                    const contract = this.makeContract(Mathilda2ABI, contractAddress);
                    return contract.methods.favorPerUser(address);

                },
                transformResult: (result) => {
                    return { value: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "theMayorFavorPerUser": {
                // fn: this.theMayorFavorPerUser,
                makeMethod: (args) => {
                    const { contractAddress, address } = this.parseArgs(args);
                    const contract = this.makeContract(TheMayor2ABI, contractAddress);
                    return contract.methods.favorPerUser(address);
                },
                transformResult: (result) => {
                    return { value: result };
                },
                isReadOnly: true,
                batchable: true,
            },

            "theMayorGetRecipes": {
                // fn: this.theMayorGetRecipes,
                makeMethod: (args) => {
                    const { contractAddress } = this.parseArgs(args);
                    const contract = this.makeContract(TheMayor2ABI, contractAddress);
                    return contract.methods.getRecipes();
                },
                transformResult: (result) => {
                    const recipes = result;
                    const value = recipes.map(r => {
                        return {
                            "name": r.name,
                            "requiredLumiAmount": r.requiredLumiAmount,
                            "resultClass": r.resultClass,
                            "resultType": r.resultType,
                            "favorEarn": r.favorEarn,
                            "favorRequire": r.favorRequire,
                            "sellCounter": r.sellCounter,
                            "disabled": r.disabled,
                            "isConsumable": r.isConsumable,
                        }
                    });
                    return { value };

                },
                isReadOnly: true,
                batchable: true,
            },
            "mathildaGetRecipes": {
                fn: this.mathildaGetRecipes,
                makeMethod: (args) => {
                    const { contractAddress } = this.parseArgs(args);
                    const contract = this.makeContract(Mathilda2ABI, contractAddress);
                    return contract.methods.getRecipes();
                },
                transformResult: (result) => {
                    const recipes = result;
                    const value = recipes.map(r => {
                        return {
                            "name": r.name,
                            "requiredKAP20Address": r.requiredKAP20Address,
                            "requiredKAP20Amount": r.requiredKAP20Amount,
                            "requiredRescType": r.requiredRescType,
                            "requiredRescAmount": r.requiredRescAmount,
                            "requiredNftClass": r.requiredNftClass,
                            "requiredNftType": r.requiredNftType,
                            "resultClass": r.resultClass,
                            "resultType": r.resultType,
                            "favorEarn": r.favorEarn,
                            "favorRequire": r.favorRequire,
                            "disabled": r.disabled,
                            "isConsumable": r.isConsumable,
                            "isBackwardCompatible": r.isBackwardCompatible,
                        }
                    });
                    return { value };
                },
                isReadOnly: true,
                batchable: true,
            },
            "kyleGetRecipes": {
                fn: this.kyleGetRecipes,
                isReadOnly: true,
                batchable: false, // we do not set as batchabled, since 2 sequentially calls required 
            },
            "uncleJackGetRecipes": {
                fn: this.uncleJackGetRecipes,
                makeMethod: (args) => {
                    const { contractAddress } = this.parseArgs(args);
                    const contract = this.makeContract(UncleJackABI, contractAddress);
                    return contract.methods.getRecipes();
                },
                transformResult: (result) => {
                    const recipes = result;
                    const value = recipes.map(r => {
                        return {
                            "name": r.name,
                            "requiredRescType": r.requiredRescType,
                            "requiredRescAmount": r.requiredRescAmount,
                            "favorEarn": r.favorEarn,
                            "favorRequire": r.favorRequire,
                            "resultAmount": r.resultAmount,
                            "resultToken": r.resultToken,
                            "disabled": r.disabled,
                        }
                    });
                    return { value };
                },
                isReadOnly: true,
                batchable: true,
            },
            "uncleJackGetTreasureRecipes": {
                fn: this.uncleJackGetTreasureRecipes,
                makeMethod: (args) => {
                    const { contractAddress } = this.parseArgs(args);
                    const contract = this.makeContract(UncleJackABI, contractAddress);
                    return contract.methods.getTreasureRecipes();
                },
                transformResult: (result) => {
                    const recipes = result;
                    const value = recipes.map(r => {
                        return {
                            "name": r.name,
                            "requiredNFTClass": r.requiredNftClass,
                            "requiredNFTType": r.requiredNftType,
                            "favorEarn": r.favorEarn,
                            "favorRequire": r.favorRequire,
                            "resultTokens": r.resultTokens,
                            "disabled": r.disabled,
                        }
                    });
                    return { value };
                },
                isReadOnly: true,
                batchable: true,
            },
            "uncleJackGetIsRescAndIndex": {
                fn: this.uncleJackGetIsRescAndIndex,
                makeMethod: (args) => {
                    const { contractAddress, name } = this.parseArgs(args);
                    const contract = this.makeContract(UncleJackABI, contractAddress);
                    return contract.methods.getIsRescAndIndex(name);
                },
                transformResult: (result) => {
                    const value = {
                        isResourceTypeRecipe: result[0],
                        recipeIndex: result[1],
                    };
                    return { value };
                },
                isReadOnly: true,
                batchable: true,
            },
            "kyleGetIsCropAndIndex": {
                fn: this.kyleGetIsCropAndIndex,
                makeMethod: (args) => {
                    const { contractAddress, name } = this.parseArgs(args);
                    const contract = this.makeContract(KyleABI, contractAddress);
                    return contract.methods.getIsCropAndIndex(name);
                },
                transformResult: (result) => {
                    const value = {
                        isCropTypeRecipe: result[0],
                        recipeIndex: result[1],
                    };
                    return { value };
                },
                isReadOnly: true,
                batchable: true,
            },
            "mathildaNameToIndex": {
                fn: this.mathildaNameToIndex,
                makeMethod: (args) => {
                    const { contractAddress, name } = this.parseArgs(args);
                    const contract = this.makeContract(Mathilda2ABI, contractAddress);
                    return contract.methods.nameToIndex(name);
                },
                transformResult: (result) => {
                    return {
                        value: {
                            recipeIndex: result,
                        }
                    };
                },
                isReadOnly: true,
                batchable: true,
            },
            "theMayorNameToIndex": {
                fn: this.theMayorNameToIndex,
                makeMethod: (args) => {
                    const { contractAddress, name } = this.parseArgs(args);
                    const contract = this.makeContract(TheMayor2ABI, contractAddress);
                    return contract.methods.nameToIndex(name);
                },
                transformResult: (result) => {
                    return {
                        value: {
                            recipeIndex: result
                        }
                    };
                },
                isReadOnly: true,
                batchable: true,
            },

            "svenFavorPerUser": {
                makeMethod: (args) => {
                    const { contractAddress, address } = this.parseArgs(args);
                    const contract = this.makeContract(SvenABI, contractAddress);
                    return contract.methods.favorPerUser(address);
                },
                transformResult: (result) => {
                    return { value: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "svenGetRecipes": {
                makeMethod: (args) => {
                    const { contractAddress } = this.parseArgs(args);
                    const contract = this.makeContract(SvenABI, contractAddress);
                    return contract.methods.getRecipes();
                },
                transformResult: (result) => {
                    const recipes = result;
                    const value = recipes.map(r => {
                        return {
                            "name": r.name,
                            "price": r.price, // in wei
                            "resultClass": r.resultClass,
                            "resultType": r.resultType,
                            "favorEarn": r.favorEarn,
                            "favorRequire": r.favorRequire,
                            "sellCounter": r.sellCounter,
                            "disabled": r.disabled,
                            "isConsumable": r.isConsumable,
                        }
                    });
                    return { value };
                },
                isReadOnly: true,
                batchable: true,
            },
            "svenNameToIndex": {
                makeMethod: (args) => {
                    const { contractAddress, name } = this.parseArgs(args);
                    const contract = this.makeContract(SvenABI, contractAddress);
                    return contract.methods.nameToIndex(name);
                },
                transformResult: (result) => {
                    return {
                        value: {
                            recipeIndex: result
                        }
                    };
                },
                isReadOnly: true,
                batchable: true,
            },
            "svenAvailableReserve": {
                makeMethod: (args) => {
                    const { contractAddress, phaseName } = this.parseArgs(args);
                    const contract = this.makeContract(SvenABI, contractAddress);
                    return contract.methods.availableReserve(phaseName);
                },
                transformResult: (result) => {
                    return {
                        reserve: result,
                    };
                },
                isReadOnly: true,
                batchable: true,
            },
            "svenCurrentReservePhase": {
                makeMethod: (args) => {
                    const { contractAddress } = this.parseArgs(args);
                    const contract = this.makeContract(SvenABI, contractAddress);
                    return contract.methods.currentReservePhase();
                },
                transformResult: (result) => {
                    return {
                        reservePhase: result,
                    };
                },
                isReadOnly: true,
                batchable: true,
            },

            "getClassTypeFromKAP20ConsumableAddress": {
                fn: this.getClassTypeFromKAP20ConsumableAddress,
                makeMethod: (args) => {
                    const {
                        contractAddress,
                        kap20Address,
                    } = this.parseArgs(args);
                    const contract = this.makeContract(ConsumableMapABI, contractAddress);
                    return contract.methods.getClassType(kap20Address);
                },
                transformResult: (result) => {
                    const values = result;
                    const itemClass = values[0];
                    const itemType = values[1];
                    return {
                        itemClass,
                        itemType,
                    };
                },
                isReadOnly: true,
                batchable: true,
            },
            "getKAP20ConsumableAddressByClassType": {
                fn: this.getKAP20ConsumableAddressByClassType,
                makeMethod: (args) => {
                    const {
                        contractAddress,
                        itemClass,
                        itemType,
                    } = this.parseArgs(args);
                    const contract = this.makeContract(ConsumableMapABI, contractAddress);
                    return contract.methods.classTypeToAddress(itemClass, itemType);
                },
                transformResult: (result) => {
                    return { kap20Address: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "tokenHolderHelperGetTokenAddressByPage": {
                fn: this.tokenHolderHelperGetTokenAddressByPage,
                makeMethod: (args) => {
                    const {
                        contractAddress,
                        page,
                        limit,
                    } = this.parseArgs(args);
                    const contract = this.makeContract(TokenHolderHelper, contractAddress);
                    return contract.methods.getTokenAddressByPage(page, limit);
                },
                transformResult: (result) => {
                    return { addresses: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "tokenHolderHelperGetConsumableAmount": {
                fn: this.tokenHolderHelperGetConsumableAmount,
                makeMethod: (args) => {
                    const {
                        contractAddress,
                        addresses, // array of KAP20 address
                        ownerAddress, // wallet address
                    } = this.parseArgs(args);
                    const contract = this.makeContract(TokenHolderHelper, contractAddress);
                    return contract.methods.getConsumableAmount(addresses, ownerAddress);
                },
                transformResult: (result) => {
                    return { amounts: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "tokenHolderHelperGetTokenOfOwnerAll": {
                fn: this.tokenHolderHelperGetTokenOfOwnerAll,
                makeMethod: (args) => {
                    const {
                        contractAddress,
                        ownerAddress,
                    } = this.parseArgs(args);
                    const contract = this.makeContract(MorningMoonNFTABI, contractAddress);
                    return contract.methods.tokenOfOwnerAll(ownerAddress);
                },
                transformResult: (result) => {
                    return { tokens: result };
                },
                isReadOnly: true,
                batchable: false, // we do not make it batchable, since it might make too large size of RPC call response which may cause that call broken
            },
            "tokenHolderHelperGetTokenOfOwnerByPage": {
                fn: this.tokenHolderHelperGetTokenOfOwnerByPage,
                makeMethod: (args) => {
                    const {
                        contractAddress,
                        ownerAddress,
                        page,
                        limit,
                    } = this.parseArgs(args);
                    const contract = this.makeContract(MorningMoonNFTABI, contractAddress);
                    return contract.methods.tokenOfOwnerByPage(ownerAddress, page, limit);
                },
                transformResult: (result) => {
                    return { tokens: result };
                },
                isReadOnly: true,
                batchable: false,  // we do not make it batchable, since it might make too large size of RPC call response which may cause that call broken
            },
            "mmvShopGetKAP20ItemInfoByNames": {
                fn: this.mmvShopGetKAP20ItemInfoByNames,
                isReadOnly: true,
                batchable: false, // we do not set as batchabled, since 2 sequentially calls required 
            },
            "mmvShopGetKAP721ItemInfoByNames": {
                fn: this.mmvShopGetKAP721ItemInfoByNames,
                isReadOnly: true,
                batchable: false, // we do not set as batchabled, since 2 sequentially calls required 
            },
            "mmvShopGetOpenTime": {
                makeMethod: (args) => {
                    const {
                        mmvShopAddress,
                    } = this.parseArgs(args);
                    const contract = this.makeContract(MMVCropShopV1, mmvShopAddress);
                    return contract.methods.openTime();
                },
                transformResult: (result) => {
                    return { openTime: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "mmvShopGetCloseTime": {
                makeMethod: (args) => {
                    const {
                        mmvShopAddress,
                    } = this.parseArgs(args);
                    const contract = this.makeContract(MMVCropShopV1, mmvShopAddress);
                    return contract.methods.closeTime();
                },
                transformResult: (result) => {
                    return { closeTime: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "mmvShopBuyKAP20Item": {
                fn: this.mmvShopBuyKAP20Item,
                isReadOnly: false,
                batchable: false,
            },
            "mmvShopBuyKAP721Item": {
                fn: this.mmvShopBuyKAP721Item,
                isReadOnly: false,
                batchable: false,
            },
            "morningMoonWrappedTokenDeposit": {
                fn: this.morningMoonWrappedTokenDeposit,
                isReadOnly: false,
                batchable: false,
            },
            "lumiStemUnwrapperLock": {
                fn: this.lumiStemUnwrapperLock,
                isReadOnly: false,
                batchable: false,
            },
            "lumiStemUnwrapperUnlock": {
                fn: this.lumiStemUnwrapperUnlock,
                isReadOnly: false,
                batchable: false,
            },
            "lumiStemUnwrapperClaim": {
                fn: this.lumiStemUnwrapperClaim,
                isReadOnly: false,
                batchable: false,
            },
            "lumiStemUnwrapperGetLockInfo": {
                makeMethod: (args) => {
                    const { contractAddress, userAddress } = this.parseArgs(args);
                    const contract = this.makeContract(LumiStemUnwrapper, contractAddress);
                    return contract.methods.lockInfo(userAddress);
                },
                transformResult: (result) => {
                    let lockDate, amount;
                    if (Array.isArray(result)) {
                        [lockDate, amount] = result;
                    } else {
                        const { lockDate: d, amount: a } = result;
                        lockDate = d;
                        amount = a;
                    }
                    return {
                        lockInfo: {
                            lockDate,
                            amount,
                        },
                    };
                },
                isReadOnly: true,
                batchable: true,
            },
            "lumiStemUnwrapperGetLockPeriod": {
                makeMethod: (args) => {
                    const { contractAddress } = this.parseArgs(args);
                    const contract = this.makeContract(LumiStemUnwrapper, contractAddress);
                    return contract.methods.lockPeriod();
                },
                transformResult: (result) => {
                    return { lockPeriod: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            // DAO
            "daoGetCurrentPollIndex": {
                makeMethod: (args) => {
                    const { contractAddress } = this.parseArgs(args);
                    const contract = this.makeContract(MorningMoonDAO, contractAddress);
                    return contract.methods.currentPollIndex();
                },
                transformResult: (result) => {
                    return { index: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "daoGetPoll": {
                makeMethod: (args) => {
                    const { contractAddress, pollIndex } = this.parseArgs(args);
                    const contract = this.makeContract(MorningMoonDAO, contractAddress);
                    return contract.methods.poll(pollIndex);
                },
                transformResult: (result) => {
                    const {
                        ipfsHash,
                        options,
                        timestampStart,
                        timestampRound2,
                        timestampEnd,
                        canceled,
                    } = result;
                    return {
                        poll: {
                            ipfsHash: ipfsHash,
                            options: options.map(e => e.toString()),
                            timestampStart,
                            timestampRound2,
                            timestampEnd,
                            canceled,
                        }
                    };
                },
                isReadOnly: true,
                batchable: true,
            },
            "daoGetTotalParticipants": {
                makeMethod: (args) => {
                    const { contractAddress, pollIndex } = this.parseArgs(args);
                    const contract = this.makeContract(MorningMoonDAO, contractAddress);
                    return contract.methods.totalParticipants(pollIndex);
                },
                transformResult: (result) => {
                    return { numberOfParticipants: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "daoGetUserInfo": {
                makeMethod: (args) => {
                    const { contractAddress, userAddress } = this.parseArgs(args);
                    const contract = this.makeContract(MorningMoonDAO, contractAddress);
                    return contract.methods.userInfo(userAddress);
                },
                transformResult: (result) => {
                    const values = result;
                    const currentPollIndex = values[0]
                    const currentOption = values[1]
                    const lumiAmount = values[2]
                    const quotaAmount = values[3]
                    return {
                        userInfo: {
                            currentPollIndex,
                            currentOption,
                            lumiAmount,
                            quotaAmount,
                        }
                    };
                },
                isReadOnly: true,
                batchable: true,
            },
            "daoVote": {
                fn: this.daoVote,
                isReadOnly: false,
                batchable: false,
            },
            "daoRemoveVote": {
                fn: this.daoRemoveVote,
                isReadOnly: false,
                batchable: false,
            },
            "daoAddVote": {
                fn: this.daoAddVote,
                isReadOnly: false,
                batchable: false,
            },
            "daoClaimVote": {
                fn: this.daoClaimVote,
                isReadOnly: false,
                batchable: false,
            },
            "kubLumiGillShopZapKUB": {
                fn: this.kubLumiGillShopZapKUB,
                isReadOnly: false,
                batchable: false,
            },
            "kubLumiGillShopZapKKUB": {
                fn: this.kubLumiGillShopZapKKUB,
                isReadOnly: false,
                batchable: false,
            },
            "kubLumiGillShopRemoveLP": {
                fn: this.kubLumiGillShopRemoveLP,
                isReadOnly: false,
                batchable: false,
            },
            "kubLumiGillShopGetLPFromKKUB": {
                makeMethod: (args) => {
                    const { contractAddress, amountInWei } = this.parseArgs(args);
                    const contract = this.makeContract(KUBLumiGillShop, contractAddress);
                    return contract.methods.getLPFromKKUB(amountInWei);
                },
                transformResult: (result) => {
                    return { liquidity: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "kubLumiGillShopGetLumiAmountFromLP": {
                makeMethod: (args) => {
                    const { contractAddress, amountInWei } = this.parseArgs(args);
                    const contract = this.makeContract(KUBLumiGillShop, contractAddress);
                    return contract.methods.getLumiAmountFromLP(amountInWei);
                },
                transformResult: (result) => {
                    return { lumiAmountInWei: result };
                },
                isReadOnly: true,
                batchable: true,
            },
            "lumiGillStemHelperGetLumiAmountFromLP": {
                makeMethod: (args) => {
                    const { contractAddress, amountInWei } = this.parseArgs(args);
                    const contract = this.makeContract(LumiGillStemHelper, contractAddress);
                    return contract.methods.getLumiAmountFromLP(amountInWei);
                },
                transformResult: (result) => {
                    return { lumiAmountInWei: result };
                },
                isReadOnly: true,
                batchable: true,
            },
        };
        return fnMap;
    }

    makeContract(abi, address) {
        return new (this.web3Instance()).eth.Contract(abi, address);
    }

    async getMyWalletAddress(callID, args = "") {
        const address = this.web3Instance().eth.defaultAccount;
        this.delegator.resultCallback(callID, {
            address,
        });
    }

    async getMyKUBBalance(callID, args = "") {
        const { address } = this.parseArgs(args);
        const balance = await this.web3Instance().eth.getBalance(address);
        this.delegator.resultCallback(callID, {
            balance,
        });
    }

    async getKAP20BalanceOf(callID, args = "") {
        const logTag = `[ContractExecutor::getKAP20BalanceOf::${callID}]`;
        const { contractAddress, address } = this.parseArgs(args);
        const contract = this.makeContract(KAP20TokenABI, contractAddress);
        let balance = 0;
        try {
            balance = await this.handleContractCall(logTag, contract.methods.balanceOf(address));
        } catch (ex) {
            console.error(`${logTag} args: ${JSON.stringify(args)}`);
            console.error(ex);
        }
        this.delegator.resultCallback(callID, {
            balance,
        });
    }

    async getKAP20Allowance(callID, args = "") {
        const logTag = `[ContractExecutor::getKAP20Allowance::${callID}]`;
        const { tokenAddress, ownerAddress, spenderAddress } = this.parseArgs(args);
        let remaining = 0;
        const kusdtTestnet = "0x1f86f79f109060725b6f4146baee9b7aca41267d";
        const kusdtMainnet = "0x7d984c24d2499d840eb3b7016077164e15e5faa6";
        if (tokenAddress.toLowerCase() === kusdtTestnet ||
            tokenAddress.toLowerCase() === kusdtMainnet) {
            const contract = this.makeContract(KAP20TokenKUSDTABI, tokenAddress);
            remaining = await this.handleContractCall(logTag, contract.methods.allowances(ownerAddress, spenderAddress));
        } else {
            const contract = this.makeContract(KAP20TokenABI, tokenAddress);
            remaining = await this.handleContractCall(logTag, contract.methods.allowance(ownerAddress, spenderAddress));
        }
        this.delegator.resultCallback(callID, {
            remaining,
        });
    }

    async getCurrentBlockNumber(callID, args = "") {
        const blockNumber = await this.web3Instance().eth.getBlockNumber();
        this.delegator.resultCallback(callID, {
            blockNumber
        });
    }

    async approveLiquidityPool(callID, args = "") {
        const { poolContractAddress, address, amount, spenderAddress } = this.parseArgs(args);
        const contract = this.makeContract(MMVPairABI, poolContractAddress);
        const txObj = contract.methods.approve(spenderAddress, amount);
        this.handleEventAndResolvePromiEvent(callID,
            txObj.send({
                from: address,
                ...await this.defaultTxSendOptions(txObj),
            }),
        );
    }

    async getLiquidityPoolAllowance(callID, args = "") {
        const logTag = `[ContractExecutor::getLiquidityPoolAllowance::${callID}]`;
        const {
            poolContractAddress,
            ownerAddress,
            spenderAddress,
        } = this.parseArgs(args);
        const contract = this.makeContract(MMVPairABI, poolContractAddress);
        const remaining = await this.handleContractCall(logTag, contract.methods.allowance(ownerAddress, spenderAddress));
        this.delegator.resultCallback(callID, {
            remaining,
        })
    }

    async approveSeedToken(callID, args = "") {
        this.approveKAP20Token(callID, args);
    }

    async approveKAP20Token(callID, args = "") {
        const { tokenAddress, address, amount, spenderAddress } = this.parseArgs(args);
        const contract = this.makeContract(KAP20TokenABI, tokenAddress);
        const txObj = contract.methods.approve(spenderAddress, amount);
        const opt = {
            from: address,
            ...await this.defaultTxSendOptions(txObj),
        };
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send(opt));
    }

    async getLiquidityPoolAllowance(callID, args = "") {
        const logTag = `[ContractExecutor::getLiquidityPoolAllowance::${callID}]`;
        const { poolContractAddress, ownerAddress, spenderAddress } = this.parseArgs(args);
        const contract = this.makeContract(MMVPairABI, poolContractAddress);
        let remaining = 0;
        try {
            remaining = await this.handleContractCall(logTag, contract.methods.allowance(ownerAddress, spenderAddress));
        } catch (ex) {
            remaining = 0;
        }
        this.delegator.resultCallback(callID, { remaining });
    }

    async getBalanceOfLiquidityPool(callID, args = "") {
        const logTag = `[ContractExecutor::getBalanceOfLiquidityPool::${callID}]`;
        const { poolContractAddress, address } = this.parseArgs(args);
        const contract = this.makeContract(MMVPairABI, poolContractAddress);
        let balance = 0;
        try {
            balance = await this.handleContractCall(logTag, contract.methods.balanceOf(address))
        } catch (ex) {
            balance = 0;
        }
        this.delegator.resultCallback(callID, { balance });
    }

    async getLiquidityPoolTotalSupply(callID, args = "") {
        const logTag = `[ContractExecutor::getLiquidityPoolTotalSupply::${callID}]`;
        const { poolContractAddress } = this.parseArgs(args);
        const contract = this.makeContract(MMVPairABI, poolContractAddress);
        let totalSupply = 0;
        try {
            totalSupply = await this.handleContractCall(logTag, contract.methods.totalSupply());
        } catch (ex) {
            totalSupply = 0;
        }
        this.delegator.resultCallback(callID, { totalSupply });
    }

    async getLiquidityPoolReserves(callID, args = "") {
        const logTag = `[ContractExecutor::getLiquidityPoolReserves::${callID}]`;
        const { poolContractAddress } = this.parseArgs(args);
        const contract = this.makeContract(MMVPairABI, poolContractAddress);
        const { reserve0, reserve1 } = await this.handleContractCall(logTag, contract.methods.getReserves());
        this.delegator.resultCallback(callID, { reserve0, reserve1 });
    }

    async getLiquidityPoolToken0Address(callID, args = "") {
        const logTag = `[ContractExecutor::getLiquidityPoolToken0Address::${callID}]`;
        const { poolContractAddress } = this.parseArgs(args);
        const contract = this.makeContract(MMVPairABI, poolContractAddress);
        let address = "";
        try {
            address = await this.handleContractCall(logTag, contract.methods.token0());
        } catch (ex) {
            address = "";
        }
        this.delegator.resultCallback(callID, { address: address.toLowerCase() });
    }

    async getLiquidityPoolToken1Address(callID, args = "") {
        const logTag = `[ContractExecutor::getLiquidityPoolToken1Address::${callID}]`;
        const { poolContractAddress } = this.parseArgs(args);
        const contract = this.makeContract(MMVPairABI, poolContractAddress);
        let address = "";
        try {
            address = await this.handleContractCall(logTag, contract.methods.token1());
        } catch (ex) {
            address = "";
        }
        this.delegator.resultCallback(callID, { address: address.toLowerCase() });
    }

    //#region - seed contract function

    async getSeedTokenAllowance(callID, args = "") {
        const logTag = `[ContractExecutor::getSeedTokenAllowance::${callID}]`;
        const { seedContractAddress, ownerAddress, spenderAddress } = this.parseArgs(args);
        const contract = this.makeContract(KAP20TokenABI, seedContractAddress);
        const remaining = await this.handleContractCall(logTag, contract.methods.allowance(ownerAddress, spenderAddress));
        this.delegator.resultCallback(callID, { remaining });
    }

    async getBalanceOfSeedToken(callID, args = "") {
        const logTag = `[ContractExecutor::getBalanceOfSeedToken::${callID}]`;
        const { seedContractAddress, address } = this.parseArgs(args);
        const contract = this.makeContract(KAP20TokenABI, seedContractAddress);
        const balance = await this.handleContractCall(logTag, contract.methods.balanceOf(address));
        this.delegator.resultCallback(callID, { balance });
    }

    //#endregion


    //#region stem farm v2

    async stemFarmV2GetUserInfo(callID, args = "") {
        const logTag = `[ContractExecutor::stemFarmV2GetUserInfo::${callID}]`;
        const { farmContractAddress, address } = this.parseArgs(args);
        const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
        const { amount, nftPower, rewardDebt, quotaUsed } = await this.handleContractCall(logTag, contract.methods.userInfo(address));
        this.delegator.resultCallback(callID, {
            userInfo: {
                amount,
                nftPower,
                rewardDebt,
                quotaUsed,
            }
        });
    }

    //new
    async stemFarmV2GetTotalStakedValue(callID, args = "") {
        const logTag = `[ContractExecutor::stemFarmV2GetTotalStakedValue::${callID}]`;
        const { farmContractAddress } = this.parseArgs(args);
        const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
        const stakedValue = await this.handleContractCall(logTag, contract.methods.totalStakedValue());
        this.delegator.resultCallback(callID, {
            stakedValue,
        });
    }

    //new
    async stemFarmV2GetUserStakedNFTLength(callID, args = "") {
        const logTag = `[ContractExecutor::stemFarmV2GetUserStakedNFTLength::${callID}]`;
        const { farmContractAddress, address } = this.parseArgs(args);
        const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
        const numberOfStakedNFTs = await this.handleContractCall(logTag, contract.methods.userStakedNFTLength(address));
        this.delegator.resultCallback(callID, {
            numberOfStakedNFTs,
        });
    }

    //new
    async stemFarmV2GetUserStakedNFTs(callID, args = "") {
        const logTag = `[ContractExecutor::stemFarmV2GetUserStakedNFTs::${callID}]`;
        const { farmContractAddress, address, page, limit } = this.parseArgs(args);
        const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
        const stakedNFTs = await this.handleContractCall(logTag, contract.methods.userStakedNFTs(address, page, limit));
        this.delegator.resultCallback(callID, {
            stakedNFTs,
        });
    }

    async stemFarmV2GetRewardPerBlock(callID, args = "") {
        const logTag = `[ContractExecutor::StemFarmV2GetRewardPerBlock::${callID}]`;
        const { farmContractAddress } = this.parseArgs(args);
        const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
        const rewardPerBlock = await this.handleContractCall(logTag, contract.methods.getRewardPerBlock());
        this.delegator.resultCallback(callID, { rewardPerBlock });
    }

    async stemFarmV2GetBaseRewardMultiplier(callID, args = "") {
        const logTag = `[ContractExecutor::stemFarmV2GetBaseRewardMultiplier::${callID}]`;
        const { farmCentralContractAddress, farmContractAddress } = this.parseArgs(args);
        const contract = this.makeContract(FarmCentralABI, farmCentralContractAddress);
        const pid = await this.handleContractCall(logTag, contract.methods.getPid(farmContractAddress));
        const { _, allocPoint } = await this.handleContractCall(logTag, contract.methods.poolInfo(pid));
        const multiplier = allocPoint;
        this.delegator.resultCallback(callID, { multiplier });
    }

    async stemFarmV2GetPendingReward(callID, args = "") {
        const logTag = `[ContractExecutor::stemFarmV2GetPendingReward::${callID}]`;
        const { farmContractAddress, address } = this.parseArgs(args);
        const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
        const pendingReward = await this.handleContractCall(logTag, contract.methods.pendingReward(address));
        this.delegator.resultCallback(callID, { pendingReward });
    }

    //new
    async stemFarmV2GetFarmLevel(callID, args = "") {
        const logTag = `[ContractExecutor::stemFarmV2GetFarmLevel::${callID}]`;
        const { farmContractAddress, address } = this.parseArgs(args);
        const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
        const farmLevel = await this.handleContractCall(logTag, contract.methods.farmLevel(address));
        this.delegator.resultCallback(callID, { farmLevel });
    }

    //new
    async stemFarmV2GetUpgradePrice(callID, args = "") {
        const logTag = `[ContractExecutor::stemFarmV2GetUpgradePrice::${callID}]`;
        const { farmContractAddress, index } = this.parseArgs(args);
        const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
        const price = await this.handleContractCall(logTag, contract.methods.upgradePrice(index));
        this.delegator.resultCallback(callID, { price });
    }

    //new
    async stemFarmV2GetStakePowerMultiplier(callID, args = "") {
        const logTag = `[ContractExecutor::stemFarmV2GetStakePowerMultipliers::${callID}]`;
        const { farmContractAddress, index } = this.parseArgs(args);
        const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
        const multiplier = await this.handleContractCall(logTag, contract.methods.stakePowerMultiplier(index));
        this.delegator.resultCallback(callID, { multiplier });
    }

    async stemFarmV2Deposit(callID, args = "") {
        const { farmContractAddress, address, amount } = this.parseArgs(args);
        const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
        const txObj = contract.methods.deposit(amount);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: address,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async stemFarmV2Withdraw(callID, args = "") {
        const logTag = `[ContractExecutor::stemFarmV2Withdraw::${callID}]`;
        const { farmContractAddress, address, amount } = this.parseArgs(args);
        const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
        const txObj = contract.methods.withdraw(amount);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: address,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async stemFarmV2Harvest(callID, args = "") {
        const { farmContractAddress, address } = this.parseArgs(args);
        const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
        const txObj = contract.methods.harvest();
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: address,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    //new
    async stemFarmV2DepositNFTs(callID, args = "") {
        const { farmContractAddress, address, nftIDs } = this.parseArgs(args);
        const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
        const txObj = contract.methods.depositNFTs(nftIDs);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: address,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    //new
    async stemFarmV2WithdrawNFTs(callID, args = "") {
        const { farmContractAddress, address, nftIDs } = this.parseArgs(args);
        const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
        const txObj = contract.methods.withdrawNFTs(nftIDs);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: address,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    //new
    async stemFarmV2UpgradeFarm(callID, args = "") {
        const { farmContractAddress, address } = this.parseArgs(args);
        const contract = this.makeContract(SmartChefV2ABI, farmContractAddress);
        const txObj = contract.methods.upgradeFarm();
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: address,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    //#endregion


    //#region stem farm

    async getStemFarmUserInfo(callID, args = "") {
        const logTag = `[ContractExecutor::getStemFarmUserInfo::${callID}]`;
        const { farmContractAddress, address } = this.parseArgs(args);
        const contract = this.makeContract(SmartChefABI, farmContractAddress);
        const { amount, rewardDebt } = await this.handleContractCall(logTag, contract.methods.userInfo(address));
        this.delegator.resultCallback(callID, {
            userInfo: {
                amount,
                rewardDebt,
            }
        });
    }

    async getStemFarmRewardPerBlock(callID, args = "") {
        const logTag = `[ContractExecutor::getStemFarmRewardPerBlock::${callID}]`;
        const { farmContractAddress } = this.parseArgs(args);
        const contract = this.makeContract(SmartChefABI, farmContractAddress);
        const rewardPerBlock = await this.handleContractCall(logTag, contract.methods.getRewardPerBlock());
        this.delegator.resultCallback(callID, { rewardPerBlock });
    }

    async getStemTKFarmRewardPerBlock(callID, args = "") {
        const logTag = `[ContractExecutor::getStemTKFarmRewardPerBlock::${callID}]`;
        const { farmContractAddress, tkFarmPoolID } = this.parseArgs(args);
        const contract = this.makeContract(TKFarmABI, farmContractAddress);
        const totalRewardPerBlock = await this.handleContractCall(logTag, contract.methods.rewardPerBlock());
        const poolInfo = await this.handleContractCall(logTag, contract.methods.poolInfo(tkFarmPoolID));
        const allocPoint = poolInfo.allocPoint;
        const totalAllocPoint = await this.handleContractCall(logTag, contract.methods.totalAllocPoint());
        const bn_ = Web3.utils.toBN;
        const rewardPerBlock = bn_(totalRewardPerBlock).mul(bn_(allocPoint)).div(bn_(totalAllocPoint));
        this.delegator.resultCallback(callID, {
            rewardPerBlock: rewardPerBlock.toString(10),
        });
    }

    async getStemFarmBaseRewardMultiplier(callID, args = "") {
        const logTag = `[ContractExecutor::getStemFarmBaseRewardMultiplier::${callID}]`;
        const { farmCentralContractAddress, farmContractAddress } = this.parseArgs(args);
        const contract = this.makeContract(FarmCentralABI, farmCentralContractAddress);
        const pid = await this.handleContractCall(logTag, contract.methods.getPid(farmContractAddress));
        const { _, allocPoint } = await this.handleContractCall(logTag, contract.methods.poolInfo(pid));
        const multiplier = allocPoint;
        this.delegator.resultCallback(callID, { multiplier });
    }

    async getStemFarmPendingReward(callID, args = "") {
        const logTag = `[ContractExecutor::getStemFarmPendingReward::${callID}]`;
        const { farmContractAddress, address } = this.parseArgs(args);
        const contract = this.makeContract(SmartChefABI, farmContractAddress);
        const pendingReward = await this.handleContractCall(logTag, contract.methods.pendingReward(address));
        this.delegator.resultCallback(callID, { pendingReward });
    }

    async depositToStemFarm(callID, args = "") {
        const { farmContractAddress, address, amount } = this.parseArgs(args);
        const contract = this.makeContract(SmartChefABI, farmContractAddress);
        const txObj = contract.methods.deposit(amount);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: address,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async depositToStemTKFarm(callID, args = "") {
        const { farmContractAddress, address, amount, tkFarmPoolID } = this.parseArgs(args);
        const contract = this.makeContract(TKFarmABI, farmContractAddress);
        const txObj = contract.methods.deposit(tkFarmPoolID, amount);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: address,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async withdrawFromStemFarm(callID, args = "") {
        const { farmContractAddress, address, amount } = this.parseArgs(args);
        const contract = this.makeContract(SmartChefABI, farmContractAddress);
        const txObj = contract.methods.withdraw(amount);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: address,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async withdrawFromStemTKFarm(callID, args = "") {
        const { farmContractAddress, address, amount, tkFarmPoolID } = this.parseArgs(args);
        const contract = this.makeContract(TKFarmABI, farmContractAddress);
        const txObj = contract.methods.withdraw(tkFarmPoolID, amount);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: address,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async harvestFromStemFarm(callID, args = "") {
        const { farmContractAddress, address } = this.parseArgs(args);
        const contract = this.makeContract(SmartChefABI, farmContractAddress);
        const txObj = contract.methods.harvest();
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: address,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async harvestFromStemTKFarm(callID, args = "") {
        const { farmContractAddress, address, tkFarmPoolID } = this.parseArgs(args);
        const contract = this.makeContract(TKFarmABI, farmContractAddress);
        const to = address;
        const txObj = contract.methods.harvest(tkFarmPoolID, to);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: address,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    //#endregion

    //#region seed farm functions
    // complied with both SmartFarmerInitializable.sol and SmartFarmerV2.sol

    async getSeedFarmUserInfo(callID, args = "") {
        const logTag = `[ContractExecutor::getSeedFarmUserInfo::${callID}]`;
        const { farmContractAddress, address } = this.parseArgs(args);
        const contract = this.makeContract(SmartFarmerABI, farmContractAddress);
        const {
            userIndex, amount, rewardDebt, depositBlock
        } = await this.handleContractCall(logTag, contract.methods.userInfo(address));
        this.delegator.resultCallback(callID, {
            userInfo: {
                userIndex,
                amount,
                rewardDebt,
                depositBlock,
            }
        });
    }

    async getSeedFarmRewardPerBlock(callID, args = "") {
        const logTag = `[ContractExecutor::getSeedFarmRewardPerBlock::${callID}]`;
        const { farmContractAddress } = this.parseArgs(args);
        const contract = this.makeContract(SmartFarmerABI, farmContractAddress);
        const rewardPerBlock = await this.handleContractCall(logTag, contract.methods.getRewardPerBlock());
        this.delegator.resultCallback(callID, {
            rewardPerBlock,
        });
    }

    async getSeedFarmBaseRewardMultiplier(callID, args = "") {
        const logTag = `[ContractExecutor::getSeedFarmBaseRewardMultiplier::${callID}]`;
        const { farmCentralContractAddress, farmContractAddress } = this.parseArgs(args);
        const contract = this.makeContract(FarmCentralABI, farmCentralContractAddress);
        const pid = await this.handleContractCall(logTag, contract.methods.getPid(farmContractAddress));
        const { _, allocPoint } = await this.handleContractCall(logTag, contract.methods.poolInfo(pid));
        const multiplier = allocPoint;
        this.delegator.resultCallback(callID, { multiplier });
    }

    async getSeedFarmStakedTokenSupply(callID, args = "") {
        const logTag = `[ContractExecutor::getSeedFarmStakedTokenSupply::${callID}]`;
        const { farmContractAddress } = this.parseArgs(args);
        const contract = this.makeContract(SmartFarmerABI, farmContractAddress);
        const stakedTokenSupply = await this.handleContractCall(logTag, contract.methods.stakedTokenSupply());
        this.delegator.resultCallback(callID, {
            stakedTokenSupply,
        });
    }

    async getSeedFarmPendingReward(callID, args = "") {
        const logTag = `[ContractExecutor::getSeedFarmPendingReward::${callID}]`;
        const { farmContractAddress, address } = this.parseArgs(args);
        const contract = this.makeContract(SmartFarmerABI, farmContractAddress);
        const pendingReward = await this.handleContractCall(logTag, contract.methods.pendingReward(address));
        this.delegator.resultCallback(callID, {
            pendingReward,
        });
    }

    async getSeedFarmNumberOfBlockToWither(callID, args = "") {
        const logTag = `[ContractExecutor::getSeedFarmNumberOfBlockToWither::${callID}]`;
        const { farmContractAddress } = this.parseArgs(args);
        const contract = this.makeContract(SmartFarmerABI, farmContractAddress);
        const numberOfBlockToWither = await this.handleContractCall(logTag, contract.methods.numBlockToWither());
        this.delegator.resultCallback(callID, {
            numberOfBlockToWither,
        });
    }

    async depositToSeedFarm(callID, args = "") {
        const { farmContractAddress, address, amount } = this.parseArgs(args);
        const contract = this.makeContract(SmartFarmerABI, farmContractAddress);
        const txObj = contract.methods.deposit(amount);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: address,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async harvestFromSeedFarm(callID, args = "") {
        const { farmContractAddress, address } = this.parseArgs(args);
        const contract = this.makeContract(SmartFarmerABI, farmContractAddress);
        const txObj = contract.methods.harvest();
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: address,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    //#endregion

    //#region resource farm

    async resourceFarmGetSeedFarmAddress(callID, args = "") {
        const logTag = `[ContractExecutor::resourceFarmGetSeedFarmAddress::${callID}]`;
        const { farmContractAddress } = this.parseArgs(args);
        const contract = this.makeContract(ResourceFarmABI, farmContractAddress);
        const seedFarmAddress = await this.handleContractCall(logTag, contract.methods.seedFarm());
        this.delegator.resultCallback(callID, {
            seedFarmAddress,
        });
    }

    async resourceFarmHasGrantLicense(callID, args = "") {
        const logTag = `[ContractExecutor::resourceFarmHasGrantLicense::${callID}]`;
        const { farmContractAddress, address } = this.parseArgs(args);
        const contract = this.makeContract(ResourceFarmABI, farmContractAddress);
        const granted = await this.handleContractCall(logTag, contract.methods.hasGranted(address));
        this.delegator.resultCallback(callID, {
            granted,
        });
    }

    async resourceFarmGrantLicense(callID, args = "") {
        const { farmContractAddress, address, nftID } = this.parseArgs(args);
        const contract = this.makeContract(ResourceFarmABI, farmContractAddress);
        const txObj = contract.methods.grantLicense(nftID);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: address,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async resourceFarmRevokeLicense(callID, args = "") {
        const { farmContractAddress, address } = this.parseArgs(args);
        const contract = this.makeContract(ResourceFarmABI, farmContractAddress);
        const txObj = contract.methods.revokeLicense();
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: address,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async resourceFarmDeposit(callID, args = "") {
        const { farmContractAddress, address, resourceType, amount } = this.parseArgs(args);
        const contract = this.makeContract(ResourceFarmABI, farmContractAddress);
        let txObj = null;
        if (Array.isArray(resourceType)) {
            txObj = contract.methods["deposit(uint16[],uint256[])"](resourceType, amount);
        } else {
            txObj = contract.methods["deposit(uint16,uint256)"](resourceType, amount);
        }

        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: address,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    //#endregion

    //#region router

    async getSwappingAmountOut(callID, args = "") {
        const logTag = `[ContractExecutor::getSwappingAmountOut::${callID}]`;
        const {
            routerContractAddress,
            amountIn,
            reserveInTokenAddress,
            reserveOutTokenAddress,
        } = this.parseArgs(args);
        const contract = this.makeContract(MMVRouterABI, routerContractAddress);
        const [_, amountOut] = await this.handleContractCall(logTag, contract.methods.getAmountsOut(
            amountIn,
            [reserveInTokenAddress, reserveOutTokenAddress],
        ));
        this.delegator.resultCallback(callID, {
            amountOut,
        });
    }

    async getSwappingAmountOutWithPath(callID, args = "") {
        const logTag = `[ContractExecutor::getSwappingAmountOutWithPath::${callID}]`;
        const {
            routerContractAddress,
            amountIn,
            swappingPath,
        } = this.parseArgs(args);
        const contract = this.makeContract(MMVRouterABI, routerContractAddress);
        const amountsOut = await this.handleContractCall(logTag, contract.methods.getAmountsOut(
            amountIn,
            swappingPath,
        ));
        this.delegator.resultCallback(callID, {
            amountOut: amountsOut[amountsOut.length - 1],
        });
    }

    async getSwappingAmountIn(callID, args = "") {
        const logTag = `[ContractExecutor::getSwappingAmountIn::${callID}]`;
        const {
            routerContractAddress,
            amountOut,
            reserveInTokenAddress,
            reserveOutTokenAddress,
        } = this.parseArgs(args);
        const contract = this.makeContract(MMVRouterABI, routerContractAddress);
        const [amountIn, _] = await this.handleContractCall(logTag, contract.methods.getAmountsIn(
            amountOut,
            [reserveInTokenAddress, reserveOutTokenAddress],
        ));
        this.delegator.resultCallback(callID, {
            amountIn,
        });
    }

    async swap(callID, args = "") {
        const {
            routerContractAddress,
            address,
            amountIn,
            amountOutMin,
            reserveInTokenAddress,
            reserveOutTokenAddress,
            deadline,
        } = this.parseArgs(args);
        const contract = this.makeContract(MMVRouterABI, routerContractAddress);
        // const deadline = Date.now() + (10 * 60 * 1000); // + 20 minutes in millisec
        const txObj = contract.methods.swapExactTokensForTokens(
            amountIn,
            amountOutMin,
            [reserveInTokenAddress, reserveOutTokenAddress],
            address,
            address,
            deadline,
        );
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: address,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async swapTokensForExactTokens(callID, args = "") {
        const {
            routerContractAddress,
            address,
            amountOut,
            amountInMax,
            reserveInTokenAddress,
            reserveOutTokenAddress,
            deadline,
        } = this.parseArgs(args);
        const contract = this.makeContract(MMVRouterABI, routerContractAddress);
        const txObj = contract.methods.swapTokensForExactTokens(
            amountOut,
            amountInMax,
            [reserveInTokenAddress, reserveOutTokenAddress],
            address,
            address,
            deadline,
        );
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: address,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async swapKUB(callID, args = "") {
        const {
            routerContractAddress,
            address, // to
            kubAmount, // kub in
            amountOutMin, // amount of token out (min)
            kkubTokenAddress,
            outTokenAddress,
            deadline,
        } = this.parseArgs(args);
        const contract = this.makeContract(MMVRouterABI, routerContractAddress);
        // const deadline = Date.now() + (20 * 60 * 1000);
        const txObj = contract.methods.swapExactKUBForTokens(
            amountOutMin,
            [kkubTokenAddress, outTokenAddress],
            address,
            deadline,
        );
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: address,
                value: kubAmount, // transfering kub
                ...await this.defaultTxSendOptions(txObj, { from: address, value: kubAmount }),
            }));
    }

    //#endregion

    //#region smaart compound

    async smartCompoundZap(callID, args = "") {
        const { smartCompoundContractAddress,
            tokenAAddress,
            tokenBAddress,
            amountA,
            amountOutMinB,
            fromAddress,
            toAddress,
        } = this.parseArgs(args);
        const contract = this.makeContract(SmartCompoundABI, smartCompoundContractAddress);
        const txObj = contract.methods.zap(
            tokenAAddress,
            tokenBAddress,
            amountA,
            amountOutMinB,
            fromAddress,
            toAddress);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: fromAddress,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async smartCompoundZapKUB(callID, args = "") {
        const {
            smartCompoundContractAddress,
            address,
            tokenBAddress,
            amountOutMinB,
            kubAmount,
        } = this.parseArgs(args);
        const contract = this.makeContract(SmartCompoundABI, smartCompoundContractAddress);
        const txObj = contract.methods.zapKUB(tokenBAddress, amountOutMinB);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: address,
                value: kubAmount,
                ...await this.defaultTxSendOptions(txObj, { from: address, value: kubAmount, }),
            }));
    }

    async removeLPOneSide(callID, args = "") {
        const {
            smartCompoundContractAddress,
            address,
            tokenAAddress,
            tokenBAddress,
            liquidity,
            amountOutMinB,
            fromAddress,
            toAddress,
        } = this.parseArgs(args);
        const contract = this.makeContract(SmartCompoundABI, smartCompoundContractAddress);
        const txObj = contract.methods.removeLP(
            tokenAAddress,
            tokenBAddress,
            liquidity,
            amountOutMinB,
            fromAddress,
            toAddress,
        );
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: address,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    //#endregion

    async getTxReceipt(callID, args = "") {
        const logTag = "[getTxReceipt]";
        const {
            txHash,
        } = this.parseArgs(args);

        const tx = await this.web3Instance().eth.getTransaction(txHash);
        console.debug(logTag, "get tx:", tx);

        const receipt = await this.web3Instance().eth.getTransactionReceipt(txHash);
        if (!receipt) {
            this.delegator.resultCallback(callID, {});
            return;
        }

        const bn = receipt.blockNumber;
        const currentBn = await this.web3Instance().eth.getBlockNumber();
        const confirmedBlocks = currentBn - bn
        if (confirmedBlocks < 1) {
            console.debug(logTag, "wait for block confirm:", confirmedBlocks);
            this.delegator.resultCallback(callID, {});
            return;
        }

        this.delegator.resultCallback(callID, { receipt });
    }

    async queryTransactionLogs_obsolete(callID, args = "") {
        const logTag = "[queryTransactionLogs]";
        const {
            contractAlias,
            txHash,
            contractAddress,
        } = this.parseArgs(args);

        const tx = await this.web3Instance().eth.getTransaction(txHash);
        console.debug(logTag, "get tx:", tx);

        const abi = GetABIFromContractAlias(contractAlias);
        console.debug(logTag, "get abi:", abi);

        const contract = new (this.web3Instance()).eth.Contract(abi, contractAddress);
        console.debug(logTag, "get contract:", contract);

        const events = await contract.getPastEvents("allEvents", { fromBlock: tx.blockNumber, toBlock: tx.blockNumber });
        console.debug(logTag, "get events...", events);

        this.delegator.resultCallback(callID, { events });
    }

    async queryTransactionLogs(callID, args = "") {
        const logTag = "[queryTransactionLogs]";
        const {
            contractAlias,
            txHash,
            contractAddress,
        } = this.parseArgs(args);

        const abi = GetABIFromContractAlias(contractAlias);
        const abiDecoder = require('abi-decoder');

        abiDecoder.addABI(abi);

        const receipt = await this.web3Instance().eth.getTransactionReceipt(txHash);
        if (!receipt) {
            this.delegator.resultCallback(callID, {});
            return;
        }

        const decodedLogs = abiDecoder.decodeLogs(receipt.logs);

        /**
         * example decodedLogs...
         * "decodedLogs": [
            {
                "name": "LootBoxGetNft",
                "events": [
                    {
                        "name": "opener",
                        "type": "address",
                        "value": "0x5d617a34c8545320c04c4e17cec4b3608ef87d9e"
                    },
                    {
                        "name": "receiver",
                        "type": "address",
                        "value": "0x5d617a34c8545320c04c4e17cec4b3608ef87d9e"
                    },
                    {
                        "name": "boxId",
                        "type": "uint256",
                        "value": "42665801028117088658404504638502011784745106251142541624771491924017152"
                    },
                    {
                        "name": "nftIds",
                        "type": "uint256[]",
                        "value": [
                            "42662538815281982555828627526772437686053879724328370851878569527738368"
                        ]
                    }
                ],
                "address": "0x64695d734ea1b39F9f469915206A6e1d92b7b1C0"
            }
        ]
         */

        // transform output to the same format as web3js getPastEvents
        const events = decodedLogs.map((l, idx) => {
            return {
                event: l.name,
                logIndex: idx,
                returnValues: l.events.reduce((m, o, idx) => {
                    if (idx == 1) {
                        var mm = {};
                        mm[m.name] = m.value;
                        m = mm;
                    }
                    m[o.name] = o.value;
                    return m;
                }),
            };
        });

        this.delegator.resultCallback(callID, { events });
    }

    async queryTransactionLogs2(callID, args = "") {
        const logTag = "[queryTransactionLogs2]";
        const {
            contractAlias,
            txHash,
            contractAddress,
        } = this.parseArgs(args);

        const abiDecoder = new ABIDecoder();
        if (contractAlias.includes(",")) {
            const allAliases = contractAlias.split(",");
            for (const a of allAliases) {
                const abi = GetABIFromContractAlias(a);
                abiDecoder.addABI(abi);
            }
        } else {
            const abi = GetABIFromContractAlias(contractAlias);
            abiDecoder.addABI(abi);
        }

        const receipt = await this.web3Instance().eth.getTransactionReceipt(txHash);
        if (!receipt) {
            this.delegator.resultCallback(callID, {});
            return;
        }

        const decodedLogs = abiDecoder.decodeLogs(receipt.logs);
        // transform output to the same format as web3js getPastEvents
        const events = decodedLogs.map((l, idx) => {
            return {
                event: l.name,
                logIndex: idx,
                address: l.address,
                returnValues: l.events.reduce((m, o, idx) => {
                    if (idx == 1) {
                        var mm = {};
                        mm[m.name] = m.value;
                        m = mm;
                    }
                    m[o.name] = o.value;
                    return m;
                }),
            };
        });

        this.delegator.resultCallback(callID, { events });
    }

    async getGamePlayData(callID, args = "") {
        const logTag = "[getGamePlayData]";
        const {
            contractAddress,
            address,
        } = this.parseArgs(args);
        const contract = this.makeContract(GamePlayDataABI, contractAddress);
        const data = await this.handleContractCall(logTag, contract.methods.gameplayData(address));
        this.delegator.resultCallback(callID, {
            data,
        });
    }

    async nftGetTokenOfOwnerAll(callID, args = "") {
        const logTag = "[nftGetTokenOfOwnerAll]";
        const {
            contractAddress,
            ownerAddress,
        } = this.parseArgs(args);
        const contract = this.makeContract(MorningMoonNFTABI, contractAddress);
        const tokens = await this.handleContractCall(logTag, contract.methods.tokenOfOwnerAll(ownerAddress));
        this.delegator.resultCallback(callID, {
            tokens,
        });
    }

    async nftGetTokenOfOwnerByPage(callID, args = "") {
        const logTag = "[nftGetTokenOfOwnerByPage]";
        const {
            contractAddress,
            ownerAddress,
            page,
            limit,
        } = this.parseArgs(args);
        const contract = this.makeContract(MorningMoonNFTABI, contractAddress);
        const tokens = await this.handleContractCall(logTag, contract.methods.tokenOfOwnerByPage(ownerAddress, page, limit));
        this.delegator.resultCallback(callID, {
            tokens,
        });
    }

    async freezeNFT(callID, args = "") {
        const logTag = "[freezeNFT]";
        const {
            nftContractAddress,
            ownerAddress,
        } = this.parseArgs(args);
        console.debug(logTag, "params - contractAddress:", nftContractAddress, "ownerAddress:", ownerAddress);

        const contract = this.makeContract(MorningMoonNFTABI, nftContractAddress);
        const txObj = contract.methods.freeze(ownerAddress);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: ownerAddress,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async isNFTFreezed(callID, args = "") {
        const logTag = "[isNFTFreezed]";
        const {
            nftContractAddress,
            ownerAddress,
        } = this.parseArgs(args);
        const contract = this.makeContract(MorningMoonNFTABI, nftContractAddress);
        const freezed = await this.handleContractCall(logTag, contract.methods.freezed(ownerAddress));
        this.delegator.resultCallback(callID, {
            freezed,
        });
    }

    async mmNFTIsApprovedForAll(callID, args = "") {
        const logTag = "[mmNFTIsApprovedForAll]";
        const {
            nftContractAddress,
            ownerAddress,
            operatorAddress,
        } = this.parseArgs(args);
        const contract = this.makeContract(MorningMoonNFTABI, nftContractAddress);
        const approved = await this.handleContractCall(logTag, contract.methods.isApprovedForAll(ownerAddress, operatorAddress));
        this.delegator.resultCallback(callID, {
            approved,
        });
    }

    async mmNFTSetApprovalForAll(callID, args = "") {
        const logTag = "[mmNFTSetApprovalForAll]";
        const {
            nftContractAddress,
            ownerAddress,
            operatorAddress,
            approve,
        } = this.parseArgs(args);
        const contract = this.makeContract(MorningMoonNFTABI, nftContractAddress);
        const txObj = contract.methods.setApprovalForAll(operatorAddress, approve);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: ownerAddress,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async craftUncleJack(callID, args = "") {
        const logTag = "[craftUncleJack]";
        const {
            npcCallHelperContractAddress,
            recipeIndexes,
            amounts,
            sender,
        } = this.parseArgs(args);
        const contract = this.makeContract(NPCCallHelper3ABI, npcCallHelperContractAddress);
        const txObj = contract.methods.craftUncleJack(recipeIndexes, amounts, sender);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: sender,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async craftUncleJack2(callID, args = "") {
        const logTag = "[craftUncleJack2]";
        const {
            npcCallHelperContractAddress,
            input,
            sender,
        } = this.parseArgs(args);
        const contract = this.makeContract(NPCCallHelper3ABI, npcCallHelperContractAddress);
        const toHexString = (byteArray) => byteArray.reduce((output, elem) => (output + ('0' + elem.toString(16)).slice(-2)), '');
        const p = [
            // resource type recipes
            input.rescIndexes,
            input.rescAmounts,

            // treasure type recipes
            input.treasureIndexes,
            input.nftIds,
            `0x${toHexString(input.resultTokenIndexes.map(e => parseInt(e)))}`,
        ];
        const txObj = contract.methods.craftUncleJack(p, sender);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: sender,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async sellKyle(callID, args = "") {
        const logTag = "[sellKyle]";
        const {
            npcCallHelperContractAddress,
            input,
            sender,
        } = this.parseArgs(args);
        const contract = this.makeContract(NPCCallHelper3ABI, npcCallHelperContractAddress);

        // transform object to tuple like type
        const p = [
            input.cropIndexes,
            input.cropAmounts,
            input.nonCropIndexes,
            input.nonCropAmounts,
            input.nftIds,
        ];
        const txObj = contract.methods.sellKyle(p, sender);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: sender,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async craftMathilda(callID, args = "") {
        const logTag = "[craftMathilda]";
        const {
            npcCallHelperContractAddress,
            recipeIndexes,
            nftIds,
            amounts,
            sender,
        } = this.parseArgs(args);
        const contract = this.makeContract(NPCCallHelper3ABI, npcCallHelperContractAddress);
        const txObj = contract.methods.craftMathilda(recipeIndexes, nftIds, amounts, sender);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: sender,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async buyTheMayor(callID, args = "") {
        const logTag = "[buyTheMayor]";
        const {
            npcCallHelperContractAddress,
            recipeIndexes,
            amounts,
            sender,
        } = this.parseArgs(args);
        const contract = this.makeContract(NPCCallHelper3ABI, npcCallHelperContractAddress);
        const txObj = contract.methods.buyTheMayor(recipeIndexes, amounts, sender);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: sender,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async buySven(callID, args = "") {
        const logTag = "[buySven]";
        const {
            npcCallHelperContractAddress,
            svenContractAddress,
            recipeIndexes,
            amounts,
            sender,
        } = this.parseArgs(args);
        const contract = this.makeContract(NPCCallHelper3ABI, npcCallHelperContractAddress);
        const txObj = contract.methods.buySven(svenContractAddress, recipeIndexes, amounts, sender);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: sender,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async kyleFavorPerUser(callID, args = "") {
        const logTag = `[ContractExecutor::kyleFavorPerUser::${callID}]`;
        const { contractAddress, address } = this.parseArgs(args);
        const contract = this.makeContract(KyleABI, contractAddress);
        const favor = await this.handleContractCall(logTag, contract.methods.favorPerUser(address));
        this.delegator.resultCallback(callID, {
            value: favor,
        });
    }

    async mathildaFavorPerUser(callID, args = "") {
        const logTag = `[ContractExecutor::mathildaFavorPerUser::${callID}]`;
        const { contractAddress, address } = this.parseArgs(args);
        const contract = this.makeContract(Mathilda2ABI, contractAddress);
        const favor = await this.handleContractCall(logTag, contract.methods.favorPerUser(address));
        this.delegator.resultCallback(callID, {
            value: favor,
        });
    }

    async uncleJackFavorPerUser(callID, args = "") {
        const logTag = `[ContractExecutor::uncleJackFavorPerUser::${callID}]`;
        const { contractAddress, address } = this.parseArgs(args);
        const contract = this.makeContract(UncleJackABI, contractAddress);
        const favor = await this.handleContractCall(logTag, contract.methods.favorPerUser(address));
        this.delegator.resultCallback(callID, {
            value: favor,
        });
    }

    async theMayorFavorPerUser(callID, args = "") {
        const logTag = `[ContractExecutor::theMayorPavorPerUser::${callID}]`;
        const { contractAddress, address } = this.parseArgs(args);
        const contract = this.makeContract(TheMayor2ABI, contractAddress);
        const favor = await this.handleContractCall(logTag, contract.methods.favorPerUser(address));
        this.delegator.resultCallback(callID, {
            value: favor,
        });
    }

    async theMayorGetRecipes(callID, args = "") {
        const logTag = `[ContractExecutor::theMayorGetRecipes::${callID}]`;
        const { contractAddress } = this.parseArgs(args);
        const contract = this.makeContract(TheMayor2ABI, contractAddress);
        const recipes = await this.handleContractCall(logTag, contract.methods.getRecipes());

        const value = recipes.map(r => {
            return {
                "name": r.name,
                "requiredLumiAmount": r.requiredLumiAmount,
                "resultClass": r.resultClass,
                "resultType": r.resultType,
                "favorEarn": r.favorEarn,
                "favorRequire": r.favorRequire,
                "sellCounter": r.sellCounter,
                "disabled": r.disabled,
                "isConsumable": r.isConsumable,
            }
        });

        console.debug("value...");
        console.debug(value);

        this.delegator.resultCallback(callID, {
            value,
        });
    }

    async mathildaGetRecipes(callID, args = "") {
        const logTag = `[ContractExecutor::mathildaGetRecipes::${callID}]`;
        const { contractAddress } = this.parseArgs(args);
        const contract = this.makeContract(Mathilda2ABI, contractAddress);
        const recipes = await this.handleContractCall(logTag, contract.methods.getRecipes());

        const value = recipes.map(r => {
            return {
                "name": r.name,
                "requiredKAP20Address": r.requiredKAP20Address,
                "requiredKAP20Amount": r.requiredKAP20Amount,
                "requiredRescType": r.requiredRescType,
                "requiredRescAmount": r.requiredRescAmount,
                "requiredNftClass": r.requiredNftClass,
                "requiredNftType": r.requiredNftType,
                "resultClass": r.resultClass,
                "resultType": r.resultType,
                "favorEarn": r.favorEarn,
                "favorRequire": r.favorRequire,
                "disabled": r.disabled,
                "isConsumable": r.isConsumable,
                "isBackwardCompatible": r.isBackwardCompatible,
            }
        });
        this.delegator.resultCallback(callID, {
            value,
        });
    }

    async kyleGetRecipes(callID, args = "") {
        const logTag = `[ContractExecutor::kyleGetRecipes::${callID}]`;
        const { contractAddress } = this.parseArgs(args);
        const contract = this.makeContract(KyleABI, contractAddress);
        const cropInfos = await contract.methods.getCropInfos().call();
        const nonCropInfos = await this.handleContractCall(logTag, contract.methods.getNonCropInfos());

        const value = {
            "crop": cropInfos.map(r => {
                return {
                    "name": r.name,
                    "cropAddress": r.cropAddress,
                    "price": r.price,
                    "favorEarn": r.favorEarn,
                    "favorRequire": r.favorRequire,
                    "disabled": r.disabled,
                };
            }),
            "nonCrop": nonCropInfos.map(r => {
                return {
                    "name": r.name,
                    "nonCropClass": r.nonCropClass,
                    "nonCropType": r.nonCropType,
                    "favorEarn": r.favorEarn,
                    "favorRequire": r.favorRequire,
                    "disabled": r.disabled,
                };
            }),
        }
        this.delegator.resultCallback(callID, {
            value,
        });
    }

    async uncleJackGetRecipes(callID, args = "") {
        const logTag = `[ContractExecutor::uncleJackGetRecipes::${callID}]`;
        const { contractAddress } = this.parseArgs(args);
        const contract = this.makeContract(UncleJackABI, contractAddress);
        const recipes = await this.handleContractCall(logTag, contract.methods.getRecipes());

        const value = recipes.map(r => {
            return {
                "name": r.name,
                "requiredRescType": r.requiredRescType,
                "requiredRescAmount": r.requiredRescAmount,
                "favorEarn": r.favorEarn,
                "favorRequire": r.favorRequire,
                "resultAmount": r.resultAmount,
                "resultToken": r.resultToken,
                "disabled": r.disabled,
            }
        });
        this.delegator.resultCallback(callID, {
            value,
        });
    }

    async uncleJackGetTreasureRecipes(callID, args = "") {
        const logTag = `[ContractExecutor::uncleJackGetTreasureRecipes::${callID}]`;
        const { contractAddress } = this.parseArgs(args);
        const contract = this.makeContract(UncleJackABI, contractAddress);
        const recipes = await this.handleContractCall(logTag, contract.methods.getTreasureRecipes());

        const value = recipes.map(r => {
            return {
                "name": r.name,
                "requiredNFTClass": r.requiredNftClass,
                "requiredNFTType": r.requiredNftType,
                "favorEarn": r.favorEarn,
                "favorRequire": r.favorRequire,
                "resultTokens": r.resultTokens,
                "disabled": r.disabled,
            }
        });
        this.delegator.resultCallback(callID, {
            value,
        });
    }

    async kyleGetIsCropAndIndex(callID, args = "") {
        const logTag = `[ContractExecutor::kyleGetIsCropAndIndex::${callID}]`;
        const { contractAddress, name } = this.parseArgs(args);
        const contract = this.makeContract(KyleABI, contractAddress);
        const result = await this.handleContractCall(logTag, contract.methods.getIsCropAndIndex(name));
        const value = {
            isCropTypeRecipe: result[0],
            recipeIndex: result[1],
        };
        this.delegator.resultCallback(callID, {
            value,
        });
    }

    async uncleJackGetIsRescAndIndex(callID, args = "") {
        const logTag = `[ContractExecutor::uncleJackGetIsRescAndIndex::${callID}]`;
        const { contractAddress, name } = this.parseArgs(args);
        const contract = this.makeContract(UncleJackABI, contractAddress);
        const result = await this.handleContractCall(logTag, contract.methods.getIsRescAndIndex(name));
        const value = {
            isResourceTypeRecipe: result[0],
            recipeIndex: result[1],
        };
        this.delegator.resultCallback(callID, {
            value,
        });
    }

    async mathildaNameToIndex(callID, args = "") {
        const logTag = `[ContractExecutor::mathildaNameToIndex::${callID}]`;
        const { contractAddress, name } = this.parseArgs(args);
        const contract = this.makeContract(Mathilda2ABI, contractAddress);
        const result = await this.handleContractCall(logTag, contract.methods.nameToIndex(name));
        const value = {
            recipeIndex: result,
        };
        this.delegator.resultCallback(callID, {
            value,
        });
    }

    async theMayorNameToIndex(callID, args = "") {
        const logTag = `[ContractExecutor::theMayorNameToIndex::${callID}]`;
        const { contractAddress, name } = this.parseArgs(args);
        const contract = this.makeContract(TheMayor2ABI, contractAddress);
        const result = await this.handleContractCall(logTag, contract.methods.nameToIndex(name));
        const value = {
            recipeIndex: result,
        };
        this.delegator.resultCallback(callID, {
            value,
        });
    }

    // getKAP20ConsumableAddressByClassType(itemCall, itemType) => {kap20Address}
    async getKAP20ConsumableAddressByClassType(callID, args = "") {
        const logTag = "[getKAP20ConsumableAddressByClassType]";
        const {
            contractAddress,
            itemClass,
            itemType,
        } = this.parseArgs(args);

        const contract = this.makeContract(ConsumableMapABI, contractAddress);
        const kap20Address = await this.handleContractCall(logTag, contract.methods.classTypeToAddress(itemClass, itemType));
        this.delegator.resultCallback(callID, {
            kap20Address,
        });
    }

    // getClassTypeFromKAP20ConsumableAddress(kap20Address) => {itemClass, itemType}
    async getClassTypeFromKAP20ConsumableAddress(callID, args = "") {
        const logTag = "[getClassTypeFromKAP20ConsumableAddress]";
        const {
            contractAddress,
            kap20Address,
        } = this.parseArgs(args);

        const contract = this.makeContract(ConsumableMapABI, contractAddress);
        const values = await this.handleContractCall(logTag, contract.methods.getClassType(kap20Address));
        const itemClass = values[0];
        const itemType = values[1];
        this.delegator.resultCallback(callID, {
            itemClass,
            itemType,
        });
    }

    // tokenHolderHelperGetTokenAddressByPage(page, limit) => { addresses }
    async tokenHolderHelperGetTokenAddressByPage(callID, args = "") {
        const logTag = "[tokenHolderHelperGetTokenAddressByPage]";
        const {
            contractAddress,
            page,
            limit,
        } = this.parseArgs(args);
        const contract = this.makeContract(TokenHolderHelper, contractAddress);
        const addresses = await this.handleContractCall(logTag, contract.methods.getTokenAddressByPage(page, limit));
        this.delegator.resultCallback(callID, {
            addresses,
        });
    }

    // tokenHolderHelperGetConsumableAmount(addresses, owner) => { addresses }
    async tokenHolderHelperGetConsumableAmount(callID, args = "") {
        const logTag = "[tokenHolderHelperGetTokenAddressByPage]";
        const {
            contractAddress,
            addresses, // array of KAP20 address
            ownerAddress, // wallet address
        } = this.parseArgs(args);
        const contract = this.makeContract(TokenHolderHelper, contractAddress);
        const amounts = await this.handleContractCall(logTag, contract.methods.getConsumableAmount(addresses, ownerAddress));
        this.delegator.resultCallback(callID, {
            amounts, // array of amount (the same ordering as addresses array)
        });
    }

    async tokenHolderHelperGetTokenOfOwnerByPage(callID, args = "") {
        const logTag = "[tokenHolderHelperGetTokenOfOwnerByPage]";
        const {
            contractAddress,
            ownerAddress,
            page,
            limit,
        } = this.parseArgs(args);
        const contract = this.makeContract(MorningMoonNFTABI, contractAddress);
        const tokens = await this.handleContractCall(logTag, contract.methods.tokenOfOwnerByPage(ownerAddress, page, limit));
        this.delegator.resultCallback(callID, {
            tokens,
        });
    }

    async tokenHolderHelperGetTokenOfOwnerAll(callID, args = "") {
        const logTag = "[tokenHolderHelperGetTokenOfOwnerAll]";
        const {
            contractAddress,
            ownerAddress,
        } = this.parseArgs(args);
        const contract = this.makeContract(MorningMoonNFTABI, contractAddress);
        const tokens = await this.handleContractCall(logTag, contract.methods.tokenOfOwnerAll(ownerAddress));
        this.delegator.resultCallback(callID, {
            tokens,
        });
    }

    async mmvShopGetKAP20ItemInfoByNames(callID, args = "") {
        const logTag = "[mmvShopGetKAP20ItemInfoByNames]";
        const {
            mmvShopAddress,
            senderAddress,
            itemNames
        } = this.parseArgs(args);
        const contract = this.makeContract(MMVCropShopV1, mmvShopAddress);
        const items = await this.handleContractCall(logTag, contract.methods.getKAP20ItemInfoByNames(senderAddress, itemNames));
        this.delegator.resultCallback(callID, {
            items: items.map(m => {
                return {
                    name: m.name,
                    kap20Address: m.kap20Address,
                    limitQuantity: m.limitQuantity,
                    perUserQuota: m.perUserQuota,
                    unitPrice: m.unitPrice,
                    fulfillAmount: m.fulfillAmount,
                    available: m.available,
                    deprecated: m.deprecated,
                    itemIndex: m.itemIndex,
                    totalSold: m.totalSold,
                    userQuotaUsage: m.userQuotaUsage,
                };
            }),
        });
    }

    async mmvShopGetKAP721ItemInfoByNames(callID, args = "") {
        const logTag = "[mmvShopGetKAP721ItemInfoByNames]";
        const {
            mmvShopAddress,
            senderAddress,
            itemNames
        } = this.parseArgs(args);
        const contract = this.makeContract(MMVCropShopV1, mmvShopAddress);
        const items = await this.handleContractCall(logTag, contract.methods.getKAP721ItemInfoByNames(senderAddress, itemNames));
        this.delegator.resultCallback(callID, {
            items: items.map(m => {
                return {
                    name: m.name,
                    limitQuantity: m.limitQuantity,
                    perUserQuota: m.perUserQuota,
                    unitPrice: m.unitPrice,
                    fulfillAmount: m.fulfillAmount,
                    available: m.available,
                    deprecated: m.deprecated,
                    itemIndex: m.itemIndex,
                    totalSold: m.totalSold,
                    userQuotaUsage: m.userQuotaUsage,
                };
            }),
        });
    }

    async mmvShopBuyKAP20Item(callID, args = "") {
        const logTag = "[mmvShopBuyKAP20Item]";
        const {
            mmvShopAddress,
            sender,
            index,
            amount,
        } = this.parseArgs(args);
        const contract = this.makeContract(MMVCropShopV1, mmvShopAddress);
        const txObj = contract.methods.buyKAP20Item(index, amount);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: sender,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async mmvShopBuyKAP721Item(callID, args = "") {
        const logTag = "[mmvShopBuyKAP721Item]";
        const {
            mmvShopAddress,
            sender,
            index,
            amount,
        } = this.parseArgs(args);
        const contract = this.makeContract(MMVCropShopV1, mmvShopAddress);
        const txObj = contract.methods.buyKAP721Item(index, amount);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: sender,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async morningMoonWrappedTokenDeposit(callID, args = "") {
        const logTag = "[morningMoonWrappedTokenDeposit]";
        const {
            contractAddress,
            userAddress,
            amount,
        } = this.parseArgs(args);
        const contract = this.makeContract(MorningMoonWrappedToken, contractAddress);
        const txObj = contract.methods.deposit(amount);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: userAddress,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async lumiStemUnwrapperLock(callID, args = "") {
        const logTag = "[lumiStemUnwrapperLock]";
        const {
            contractAddress,
            userAddress,
            amount, // expected uint256
        } = this.parseArgs(args);
        const contract = this.makeContract(LumiStemUnwrapper, contractAddress);
        const txObj = contract.methods.lock(amount);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: userAddress,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async lumiStemUnwrapperUnlock(callID, args = "") {
        const logTag = "[lumiStemUnwrapperUnlock]";
        const {
            contractAddress,
            userAddress,
        } = this.parseArgs(args);
        const contract = this.makeContract(LumiStemUnwrapper, contractAddress);
        const txObj = contract.methods.unlock();
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: userAddress,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async lumiStemUnwrapperClaim(callID, args = "") {
        const logTag = "[lumiStemUnwrapperClaim]";
        const {
            contractAddress,
            userAddress,
        } = this.parseArgs(args);
        const contract = this.makeContract(LumiStemUnwrapper, contractAddress);
        const txObj = contract.methods.claim();
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: userAddress,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async daoVote(callID, args = "") {
        const {
            contractAddress,
            userAddress,
            pollIndex,
            option,
            lumiAmount,
            quotaAmount,
        } = this.parseArgs(args);
        const contract = this.makeContract(MorningMoonDAO, contractAddress);
        const txObj = contract.methods.vote(pollIndex, option, lumiAmount, quotaAmount);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: userAddress,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async daoRemoveVote(callID, args = "") {
        const {
            contractAddress,
            userAddress,
        } = this.parseArgs(args);
        const contract = this.makeContract(MorningMoonDAO, contractAddress);
        const txObj = contract.methods.removeVote();
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: userAddress,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async daoAddVote(callID, args = "") {
        const {
            contractAddress,
            userAddress,
            lumiAmount,
            quotaAmount,
        } = this.parseArgs(args);
        const contract = this.makeContract(MorningMoonDAO, contractAddress);
        const txObj = contract.methods.addVote(lumiAmount, quotaAmount);
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: userAddress,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async daoClaimVote(callID, args = "") {
        const {
            contractAddress,
            userAddress,
        } = this.parseArgs(args);
        const contract = this.makeContract(MorningMoonDAO, contractAddress);
        const txObj = contract.methods.claimVote();
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: userAddress,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async kubLumiGillShopZapKUB(callID, args = "") {
        const {
            contractAddress,
            userAddress,
            kubValueInWei,
            amountOutMinLPInWei,
        } = this.parseArgs(args);

        const contract = this.makeContract(KUBLumiGillShop, contractAddress);
        const txObj = contract.methods.zapKUB(amountOutMinLPInWei);
        this.handleEventAndResolvePromiEvent(callID, txObj.send({
            from: userAddress,
            value: kubValueInWei,
            ...await this.defaultTxSendOptions(txObj, { from: userAddress, value: kubValueInWei }),
        }));
    }

    async kubLumiGillShopZapKKUB(callID, args = "") {
        const {
            contractAddress,
            userAddress,
            kubValueInWei,
            amountOutMinLPInWei,
        } = this.parseArgs(args);

        const contract = this.makeContract(KUBLumiGillShop, contractAddress);
        const txObj = contract.methods.zapKKUB(kubValueInWei, amountOutMinLPInWei);
        this.handleEventAndResolvePromiEvent(callID, txObj.send({
            from: userAddress,
            ...await this.defaultTxSendOptions(txObj),
        }));
    }

    async kubLumiGillShopRemoveLP(callID, args = "") {
        const {
            contractAddress,
            userAddress,
            tokenAAddress,
            tokenBAddress,
            liquidity,
            amountOutMinB,
            fromAddress,
            toAddress,
        } = this.parseArgs(args);
        const contract = this.makeContract(KUBLumiGillShop, contractAddress);
        const txObj = contract.methods.removeLP(
            tokenAAddress,
            tokenBAddress,
            liquidity,
            amountOutMinB,
            fromAddress,
            toAddress,
        );
        this.handleEventAndResolvePromiEvent(
            callID,
            txObj.send({
                from: userAddress,
                ...await this.defaultTxSendOptions(txObj),
            }));
    }

    async signMessage(callID, args = "") {
        const logTag = "[signMessage]";
        const {
            message
        } = this.parseArgs(args);
        const signedMessage = await this.web3Instance().eth.personal.sign(
            Web3.utils.utf8ToHex(message),
            this.web3Instance().eth.defaultAccount);
        this.delegator.resultCallback(callID, {
            signedMessage,
            loginType: "metamask",
        })
    }

    // debugging function
    async nftGetTokenOfOwnerAtSpecificTime(ownerAddress, atTime) {
        const logTag = "[nftGetTokenOfOwnerAtSpecificTime]";
        const BLOCK_TIME_IN_SEC = 5;
        const currentBlockNumber = await this.web3Instance().eth.getBlockNumber();
        const diffSec = (Date.now() - atTime.getTime()) / 1000;
        const atBlock = Math.round(currentBlockNumber - (diffSec / BLOCK_TIME_IN_SEC));
        const contractAddress = "0x8F9000867288E087631baCb1f5961E90D1c4E9F7";
        const contract = this.makeContract(MorningMoonNFTABI, contractAddress);
        console.log(`${logTag} atTime: ${atTime} now: ${Date.now()} currentBlock: ${currentBlockNumber} atBlock: ${atBlock}`);
        const tokens = await contract.methods.tokenOfOwnerAll(ownerAddress).call(null, atBlock);
        console.log(`${logTag} tokens...`);
        console.log(JSON.stringify(tokens));
    }

    // debugging function
    async getAmountOutAtSpecificBlock(amountIn, inAddress, outAddress, atBlock) {
        const logTag = "[getAmountOutAtSpecificTime]";
        const contractAddress = "0x6e9e62018a013b20bcb7c573690fd1425ddd6b26";
        const contract = this.makeContract(MMVRouterABI, contractAddress);
        const [_, amountOut] = await contract.methods.getAmountsOut(amountIn, [inAddress, outAddress]).call(null, atBlock);
        console.log(`${logTag} block: ${atBlock} amountIn: ${amountIn} amountOut: ${amountOut}`);
    }

    /**
     * 
     * @param {*} promiEvent an object returns from send(...) method
     * @param {*} resolve (receipt) => { }
     */
    handleEventAndResolvePromiEvent(callID, promiEvent) {
        const logTag = "[JS::ContractExecutor::handleEventAndResolvePromiEvent]";
        let txHash = "";
        let sendResultOnce = false;
        promiEvent.
            once('sending', (payload) => {
                console.debug(`${logTag} event: sending... ${JSON.stringify(payload)}`);
                this.delegator.onTxSending(callID, payload);
            }).
            once('sent', (payload) => {
                console.debug(`${logTag} event: sent... ${JSON.stringify(payload)}`);
                this.delegator.onTxSent(callID, payload);
            }).
            once('transactionHash', (hash) => {
                console.debug(`${logTag} event: transactionHash... ${hash}`);
                txHash = hash;
                this.delegator.onTxHashed(callID, hash);
            }).
            once('receipt', (receipt) => {
                console.debug(`${logTag} event: receipt... ${JSON.stringify(receipt)}`);
                this.delegator.onTxSending(callID, receipt);
            }).
            on('confirmation', (confNumber, receipt, latestBlockHash) => {
                console.debug(`${logTag} event: confirmation... ${JSON.stringify({ confNumber, receipt, latestBlockHash })}`);
                this.delegator.onTxConfirmed(callID, confNumber, receipt, latestBlockHash);
                if (confNumber >= MIN_NUMBER_OF_BLOCK_CONFIRMATION && !sendResultOnce) {
                    if (receipt.status === true) {
                        this.delegator.resultCallback(callID, { txHash: receipt.transactionHash });
                        sendResultOnce = true;
                    }
                }
            }).
            on('error', (error) => {
                console.debug(`${logTag} event: error... ${JSON.stringify(error)}`);
                this.delegator.onTxError(callID, error);
            }).
            then((receipt) => {
                console.debug(`${logTag} event: receipt... ${JSON.stringify(receipt)}`);
                // this.delegator.resultCallback(callID, { txHash });
            }).
            catch((err) => {
                console.debug(`${logTag} catched error: ${JSON.stringify(err)}`);
                this.delegator.onTxError(callID, err);
            });
    }

    async handleContractCall(errLogTag, method) {
        const MAX_RETRY = 10;
        let lastException = null;

        for (let retry = 0; retry < MAX_RETRY; retry++) {
            try {
                return await method.call();
            } catch (ex) {
                lastException = ex;
                console.warn(`${errLogTag} failed to call RPC...`);
                console.warn(`${errLogTag} contractAddress: ${method._parent._address} method: ${method._method.name} args: ${JSON.stringify(method.arguments)}`);
                console.warn(ex);
                console.log(`${errLogTag} then retry (${retry}/${MAX_RETRY})`);
                continue;
            }
        }

        // still got exception after all retries done
        throw lastException;
    }
}