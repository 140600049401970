import GamePlayDataABI from "./abi/GameplayData.json";
import IKAP20ABI from "./abi/IKAP20.json";
import MorningMoonPairABI from "./abi/MorningMoonPair.json";
import SmartChefInitializableABI from "./abi/SmartChefInitializable.json";
import SmartChefV2ABI from "./abi/SmartChefV2.json";
import SmartFarmerInitializableABI from "./abi/SmartFarmerInitializable.json";
import MorningMoonRouterABI from "./abi/MorningMoonRouter.json";
import SmartCompoundABI from "./abi/SmartCompound.json";
import MorningMoonHelperABI from "./abi/MorningMoonHelper.json";
import MorningMoonHelper2ABI from "./abi/MorningMoonHelper2.json";
import NPCKyle from "./abi/Kyle.json";
import NPCKyle2 from "./abi/Kyle2.json";
import NPCUncleJack from "./abi/UncleJack2.json";
import NPCTheMayor from "./abi/TheMayor2.json";
import NPCMathilda from "./abi/Mathilda2.json";
import NPCSven from "./abi/Sven.json";
import CallHelper2 from "./abi/CallHelper2.json";
import NPCCallHelper2 from "./abi/NpcCallHelper2.json";
import NPCCallHelper4 from "./abi/NpcCallHelper4.json";
import MorningMoonNft from "./abi/MorningMoonNft.json";
import LumiStemUnwrapper from "./abi/LumiStemUnwrapper.json";
import MorningMoonWrappedToken from "./abi/MorningMoonWrappedToken.json";
import TKFarm from "./abi/TKFarm.json";
import MorningMoonDAO from "./abi/MorningMoonDAO.json";
import ResourceFarmABI from "./abi/ResourceFarm.json";

export const ContractAlias = Object.freeze({
    KAP20Lumi: "kap20-lumi",
    KAP20KKUB: "kap20-kkub",
    KAP20KUSDT: "kap20-kusdt",
    LumiKKUBPool: "pool-lumi+kkub",
    LumiKUSDTPool: "pool-lumi+kusdt",
    CropTomato: "crop-tomato",
    CropCorn: "crop-corn",
    CropCabbage: "crop-cabbage",
    CropCarrot: "crop-carrot",
    SeedTomato: "seed-tomato",
    SeedCorn: "seed-corn",
    SeedCabbage: "seed-cabbage",
    SeedCarrot: "seed-carrot",
    SeedFarmTomato: "seedfarm-{crop-tomato:seed-tomato}",
    SeedFarmCorn: "seedfarm-{crop-corn:seed-corn}",
    SeedFarmCabbage: "seedfarm-{crop-cabbage:seed-cabbage}",
    SeedFarmCarrot: "seedfarm-{crop-carrot:seed-carrot}",

    ResourceFarm: "resource-farm",

    StemFarmTomatoLK: "stemfarm-{crop-tomato:pool-lumi+kkub}",
    StemFarmCornLK: "stemfarm-{crop-corn:pool-lumi+kkub}",
    StemFarmCabbageLK: "stemfarm-{crop-cabbage:pool-lumi+kkub}",
    StemFarmCarrotLK: "stemfarm-{crop-carrot:pool-lumi+kkub}",
    StemFarmTomatoLKU: "stemfarm-{crop-tomato:pool-lumi+kusdt}",
    StemFarmCornLKU: "stemfarm-{crop-corn:pool-lumi+kusdt}",
    StemFarmCabbageLKU: "stemfarm-{crop-cabbage:pool-lumi+kusdt}",
    StemFarmCarrotLKU: "stemfarm-{crop-carrot:pool-lumi+kusdt}",
    StemFarmDandelionGKKUB: "stemfarm-{crop-dandelion:pool-gaia+kkub}",
    MorningMoonRouter: "morningmoon-router",
    MorningMoonSmartCompound: "morningmoon-smart-compound",
    GamePlayData: "game-play-data",
    SmartChefInitializable: "smartchef-initializable",
    SmartChefV2: "smartchef-v2",
    SmartFarmerInitializable: "smartfarmer-initializable",
    TokenBuilder: "tokenbuilder",
    MorningMoonHelper: "morning-moon-helper",
    MorningMoonHelper2: "morning-moon-helper2",
    MorningMoonPair: "morning-moon-pair",

    NPCKyle: "npc-kyle",
    NPCMathilda: "npc-mathilda",
    NPCTheMayor: "npc-the-mayor",
    NPCUncleJack: "npc-uncle-jack",
    NPCSven: "npc-sven",

    // client does not call these 2 contracts (NPCCallHelper2, CallHelper2) directly, but they are listed here for transaction log query 
    NPCCallHelper2: "npc-call-helper-2",
    NPCCallHelper4: "npc-call-helper-4",
    CallHelper2: "call-helper-2",

    MorningMoonNFT: "morningmoon-nft",

    NftStemFarmTomatoLKU: "nftstemfarm-{crop-tomato:pool-lumi+kusdt}",
    NftStemFarmCornLKU: "nftstemfarm-{crop-corn:pool-lumi+kusdt}",
    NftStemFarmCabbageLKU: "nftstemfarm-{crop-cabbage:pool-lumi+kusdt}",
    NftStemFarmCarrotLKU: "nftstemfarm-{crop-carrot:pool-lumi+kusdt}",
    NftFarmTeardropLKU: "nftfarm-{crop-teardrop:pool-lumi+kusdt}",

    LumiStemUnwrapper: "lumi-stem-unwrapper",
    MorningMoonWrappedToken: "morningmoon-wrapped-token",
    LumiStem: "lumi-stem",

    TKFarm: "tkfarm",
    MorningMoonDAO: "morning-moon-dao",
});

export function GetABIFromContractAlias(alias) {
    switch (alias) {
        case ContractAlias.KAP20Lumi:
        case ContractAlias.KAP20KKUB:
        case ContractAlias.KAP20KUSDT:
        case ContractAlias.CropTomato:
        case ContractAlias.CropCorn:
        case ContractAlias.CropCabbage:
        case ContractAlias.CropCarrot:
        case ContractAlias.SeedTomato:
        case ContractAlias.SeedCorn:
        case ContractAlias.SeedCabbage:
        case ContractAlias.SeedCarrot:
            return IKAP20ABI;
        case ContractAlias.MorningMoonPair:
        case ContractAlias.LumiKKUBPool:
        case ContractAlias.LumiKUSDTPool:
            return MorningMoonPairABI;
        case ContractAlias.SmartChefInitializable:
        case ContractAlias.StemFarmTomatoLK:
        case ContractAlias.StemFarmTomatoLKU:
        case ContractAlias.StemFarmCornLK:
        case ContractAlias.StemFarmCornLKU:
        case ContractAlias.StemFarmCabbageLK:
        case ContractAlias.StemFarmCabbageLKU:
        case ContractAlias.StemFarmCarrotLK:
        case ContractAlias.StemFarmCarrotLKU:
        case ContractAlias.StemFarmDandelionGKKUB:
            return SmartChefInitializableABI;
        case ContractAlias.SmartChefV2:
        case ContractAlias.NftStemFarmTomatoLKU:
        case ContractAlias.NftStemFarmCornLKU:
        case ContractAlias.NftStemFarmCabbageLKU:
        case ContractAlias.NftStemFarmCarrotLKU:
        case ContractAlias.NftFarmTeardropLKU:
            return SmartChefV2ABI;
        case ContractAlias.TKFarm:
            return TKFarm;
        case ContractAlias.SmartFarmerInitializable:
        case ContractAlias.SeedFarmTomato:
        case ContractAlias.SeedFarmCorn:
        case ContractAlias.SeedFarmCabbage:
        case ContractAlias.SeedFarmCarrot:
            return SmartFarmerInitializableABI;
        case ContractAlias.MorningMoonRouter:
            return MorningMoonRouterABI;
        case ContractAlias.GamePlayData:
            return GamePlayDataABI;
        case ContractAlias.MorningMoonSmartCompound:
            return SmartCompoundABI;
        case ContractAlias.MorningMoonHelper:
            return MorningMoonHelperABI;
        case ContractAlias.MorningMoonHelper2:
            return MorningMoonHelper2ABI;

        case ContractAlias.NPCKyle:
            return NPCKyle2;
        case ContractAlias.NPCMathilda:
            return NPCMathilda;
        case ContractAlias.NPCTheMayor:
            return NPCTheMayor;
        case ContractAlias.NPCUncleJack:
            return NPCUncleJack;
        case ContractAlias.NPCSven:
            return NPCSven;

        case ContractAlias.CallHelper2:
            return CallHelper2;
        case ContractAlias.NPCCallHelper2:
            return NPCCallHelper2;
        case ContractAlias.NPCCallHelper4:
            return NPCCallHelper4;

        case ContractAlias.MorningMoonNFT:
            return MorningMoonNft;

        case ContractAlias.MorningMoonWrappedToken:
        case ContractAlias.LumiStem:
            return MorningMoonWrappedToken;
        case ContractAlias.LumiStemUnwrapper:
            return LumiStemUnwrapper;
        case ContractAlias.MorningMoonDAO:
            return MorningMoonDAO;

        case ContractAlias.ResourceFarm:
            return ResourceFarmABI;
    }
    return null;
}